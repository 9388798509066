import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faAngleLeft, faBookmark as bookmarkIcon } from '@fortawesome/pro-regular-svg-icons';
import { faCartShoppingFast, faConveyorBeltAlt } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { CustomerFeature } from 'app/customer-integration/customer-integration';
import { Subscription } from 'rxjs';
import { openShowcasesTab } from 'user/actions/user-profile.actions';
import { UserPermission } from 'user/models/user';
import { doesUserHavePermission } from 'user/reducers/user.reducer';
import { tryToBulkHoldSelectedItems } from '../../../bulk-select/actions/bulk-select.actions';
import { BulkFeatureKey } from '../../../bulk-select/enums/bulk-feature-key';
import { hideForm, openShowcase, showForm } from '../../../custom-showcase/actions/custom-showcase.actions';
import { CustomShowcaseCreatedFromType } from '../../../custom-showcase/models/custom-showcase';
import { SitePermissions } from '../../../permissions/models/permissions';
import { FeatureToggleService } from '../../../services/feature-toggle.service';
import {
  changeListSort,
  loadMoreListItems,
  moveBookmarks,
} from '../../actions/list.actions';
import {
  BookmarksSearchFacets,
  ListItem,
  ListItemEntity,
  ListSortField,
  ListSortOrder,
  ListType,
  ListWithItemsCount,
} from '../../models/list';
import { getOpenedList, getSearchFacets, isLastPageLoaded } from '../../reducers/list.reducer';
import { ListService } from '../../services/list.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BookmarkSearch } from '../searchable-bookmarks-search-bar/searchable-bookmarks-search-bar.component';

@Component({
  selector: 'app-searchable-bookmarks-opened-list',
  templateUrl: './searchable-bookmarks-opened-list.component.html',
  styleUrls: ['./searchable-bookmarks-opened-list.component.scss']
})
export class SearchableBookmarksOpenedListComponent implements OnInit, OnDestroy {
  @Input() public lists: ListWithItemsCount[];
  @Input() public authorized: boolean;
  @Output() public closeList = new EventEmitter();

  // flags and permissions
  public isAuthPatronFlagEnabled: boolean;
  public readonly hasViewShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_VIEW));
  public readonly hasCreateShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_CREATE));
  public sitePermissions = SitePermissions;

  // icons
  public showcaseIcon = faConveyorBeltAlt;
  public placeHoldsIcon = faCartShoppingFast;
  public readonly arrowIconLeft = faAngleLeft;
  public readonly bookmarkIcon = bookmarkIcon;

  // view data
  public openedList: ListWithItemsCount = null;
  public allListItemsLoaded = false;

  // menu data
  public itemsEntitySelected: ListItemEntity[] = [];
  public itemsSelectedCount: number = 0;
  public isAllSelected: boolean = false;

  // view data - search
  public openedListSearch: ListWithItemsCount = null;
  public loading = false;
  public isSearch = false;
  public facets: BookmarksSearchFacets;
  private latestSearch: BookmarkSearch;

  // constants
  public readonly listType = ListType;
  public readonly bulkFeatureKey = BulkFeatureKey.BOOKMARKS;
  public readonly CustomerFeature = CustomerFeature;
  public readonly sortTranslateKeys = {
    date: {
      asc: {full: 'bookmarksSortByOldestFirst', short: 'bookmarksSortByOldest'},
      desc: {full: 'bookmarksSortByNewestFirst', short: 'bookmarksSortByNewest'},
    },
    title: {
      asc: {full: 'bookmarksSortByTitleAscending', short: 'bookmarksSortByTitleAZ'},
      desc: {full: 'bookmarksSortByTitleDescending', short: 'bookmarksSortByTitleZA'},
    },
  };

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly listService: ListService,
    private readonly featureToggleService: FeatureToggleService,
  ) {
  }

  ngOnInit() {
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
    this.subscriptions.add(this.store.select(getOpenedList).subscribe(openedList => {
      this.openedList = openedList;
      if (openedList) {
        this.itemsEntitySelected = openedList.items.filter((item) => item.selected).map((item) => item.entity);
        this.itemsSelectedCount = this.itemsEntitySelected.length;
        this.isAllSelected = !!(this.itemsSelectedCount && this.itemsSelectedCount === this.openedList.items.length);
        this.allListItemsLoaded = (this.authorized) ? isLastPageLoaded(this.openedList) : true;
      } else {
        this.itemsSelectedCount = 0;
        this.isAllSelected = false;
        this.allListItemsLoaded = false;
      }
      if (this.isSearch) {
        this.onSearch(this.latestSearch);
      }
    }));
    this.subscriptions.add(this.store.select(getSearchFacets).subscribe(facets => this.facets = facets));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public loadMoreItems(): void {
    this.store.dispatch(loadMoreListItems({id: this.openedList.id}));
  }

  public createShowcase(): void {
    const createdFrom = {id: this.openedList.id, name: this.openedList.name, type: CustomShowcaseCreatedFromType.list};
    this.store.dispatch(showForm({showcase: {createdFrom}}));
  }

  public viewShowcase(): void {
    this.store.dispatch(hideForm());
    this.store.dispatch(openShowcasesTab());
    this.store.dispatch(openShowcase({id: this.openedList.showcaseRef}));
  }

  public setOpenedListSort(field: ListSortField, order: ListSortOrder, sortDropdown: NgbDropdown): void {
    if (field !== this.openedList.sort.field || order !== this.openedList.sort.order) {
      this.store.dispatch(changeListSort({id: this.openedList.id, sort: {field, order}}));
    } else {
      sortDropdown.close();
    }
  }

  public setAllItemsSelection(listId: string, selected: boolean): void {
    this.isAllSelected = selected;
    this.openedList.items.forEach(item => item.selected = this.isAllSelected);
    this.updateSelected();
  }

  public updateSelected() {
    this.itemsEntitySelected = this.openedList.items.filter((item) => item.selected).map((item) => item.entity);
    this.itemsSelectedCount = this.itemsEntitySelected.length;
  }

  public onToggleItem(listItem: ListItem): void {
    const item = this.openedList.items.find(item => item.id === listItem.id);
    if (item) {
      item.selected = !item.selected;
    }
    this.updateSelected();
  }

  public removeSelectedFromList(): void {
    const items = this.itemsEntitySelected.map((item) => item);
    this.store.dispatch(moveBookmarks({entities: items, fromListIds: [this.openedList.id]}));
  }

  public bulkPlaceHolds(event: Event) {
    event.stopPropagation();
    if (this.itemsEntitySelected.length) {
      const items = this.itemsEntitySelected.map((item) => item.sourceEntity);
      this.store.dispatch(tryToBulkHoldSelectedItems({bulkFeatureKey: this.bulkFeatureKey, items}));
    }
  }

  public onSearch(search: BookmarkSearch) {
    this.loading = true;
    if (!isLastPageLoaded(this.openedList)) {
      this.loadMoreItems();
      return;
    }
    this.latestSearch = search;
    if (search.text || search.availableOnly) {
      this.isSearch = true;
    } else {
      this.isSearch = false;
    }
    this.listService.findMatches(this.openedList, search, this.facets);
    this.openedListSearch = {...this.openedList, items: this.openedList.items.filter(i => i.matched)};
    this.openedListSearch.itemsCount = this.openedListSearch.items.length;
    this.loading = false;
  }
}
