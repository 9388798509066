import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CustomerI, Page } from 'app/elements-v2/models/page';
import { DiscoverV2BffService } from 'app/elements-v2/service/discover-v2-bff.service';
import { combineLatest, Subscription } from 'rxjs';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { Configuration } from 'shared/models/configuration';
import { StaffUser, User } from 'user/models/user';
import { getUserState, UserState } from 'user/reducers/user.reducer';
import { ForLaterListDto } from 'app/list/models/list.dto';
import { ListItem, ListType, ListWithItemsCount } from 'app/list/models/list';
import { ElementsV2Service } from '../../service/elements-v2.service';
import { SiteTheme } from '../../models/settings';
import { getLists } from 'app/list/reducers/list.reducer';
import { CheckoutsState } from 'user/models/checkouts';
import { HoldsState } from 'user/models/holds';
import { ListService } from 'app/list/services/list.service';
import { FeatureToggleService } from 'app/services/feature-toggle.service';

@Component({
  selector: 'app-account-portal-v2',
  templateUrl: './account-portal-v2.component.html',
  styleUrls: ['./account-portal-v2.component.scss'],
})
export class AccountPortalV2Component implements OnInit, OnDestroy {
  @Input() translations: any = {};
  private readonly subscriptions = new Subscription();
  public customer: CustomerI;
  public config: Configuration;
  public flags?: string[];
  public user: User;
  public staffUser: StaffUser;
  public forLaterList: ForLaterListDto;
  public bookmarksList: ListItem[];
  public checkoutsCount: number;
  public holdsCount: number;
  public portalFeaturedShowcaseRef: string;
  public portalFallbackShowcaseRef: string;
  public portalFeaturedShowcaseTitle: string;
  public loading: boolean;

  constructor(
    private readonly router: Router,
    private readonly configLoader: ConfigurationLoader,
    private readonly discoverV2BffService: DiscoverV2BffService,
    private readonly elementsV2Service: ElementsV2Service,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<UserState>,
    private readonly listService: ListService,
    private readonly featureToggleService: FeatureToggleService
  ) {
    this.customer = this.discoverV2BffService.customer;
    this.config = this.configLoader.config;
    this.loading = true;
  }

  private async setupTheme() {
    await this.elementsV2Service.loadStyle(SiteTheme.VEGA_CLASSIC_V2);
  }

  ngOnInit(): void {
    this.flags = Object.entries(this.featureToggleService.getToggles())
      .filter(([_, value]) => value === true)
      .map(([key]) => key);
    this.subscriptions.add(
      combineLatest([
        this.discoverV2BffService.$page,
        this.store.select(getUserState),
        this.store.select(getLists),
      ]).subscribe(async ([page, userState, lists]) => {
        this.setUserState(page, userState, lists);
        await this.setupTheme();
        this.cdr.detectChanges();
      })
    );
  }

  public setUserState(
    page: Page,
    userState: UserState,
    lists: ListWithItemsCount[]
  ) {
    const currentUser = userState.currentUser;
    const currentStaffUser = userState.currentStaffUser;
    const checkouts = userState.checkoutsState.toJS() as CheckoutsState;
    const holds = userState.holdsState.toJS() as HoldsState;
    if (
      (currentUser || currentStaffUser) &&
      !checkouts.loadingState.loading &&
      !holds.loadingState.loading &&
      page
    ) {
      this.user = currentUser;
      this.staffUser = currentStaffUser;
      this.checkoutsCount = checkouts.cards?.length;
      this.holdsCount = holds.cards?.length;

      this.portalFeaturedShowcaseRef =
        page?.settings?.portal_featured_showcase_ref;
      this.portalFallbackShowcaseRef =
        page?.settings?.portal_fallback_showcase_ref;
      this.portalFeaturedShowcaseTitle =
        page?.settings?.portal_featured_showcase_title;

      const list = lists.find((list) => list.type === ListType.forLater);
      if (list) {
        this.forLaterList = this.converToListDto(list);
      }
      this.bookmarksList = this.listService.generateSearchPreview(lists);
      this.loading = false;
    }
  }

  public converToListDto(list: ListWithItemsCount): ForLaterListDto {
    return {
      id: list.id,
      items: {
        data: list.items.map((i) => {
          return {
            ...i.entity,
            coverUrl: i.entity.coverConfig?.coverUrl,
          };
        }),
        page: list.pagination.page,
        totalPages: list.pagination.totalPages,
        totalResults: list.pagination.totalResults,
        sortedBy: `${list.sort.field}.${list.sort.order}`,
      },
      type: ListType.forLater,
    };
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.user = null;
  }

  @HostListener('window:returnToCatalog', ['$event'])
  public onReturnClicked(event: CustomEvent) {
    this.router.navigate(['/']);
  }
}
