export function stripSpecialCharactersAndSymbols(input: string): string {
  if (typeof input !== 'string') {
    return '';
  }
  return input.replace(/[^a-zA-Z0-9 ]/g, '');
}

export function truncateString(content: string, length: number = 50): string {
  if (content?.length && content?.length > length) {
    return content.substring(0, length);
  }
  return content;
}
