<div class="search-container">
  <div class="search-input-container">
    <input #searchInput
           class="search-input"
           aria-label="What are you looking for?"
           (keydown.enter)="onKeydownEnter(searchInput.value)"
           placeholder="{{'searchInputPlaceholder' | translate}}"
           [value]=""
           data-automation-id="search-bar-input">
           <button class="clear-input-icon"
                      [class.mr-1]="!isReversedSmall && !isSmall"
                      *ngIf="searchInput.value"
                      type="button"
                      (click)="clearSearchText(searchInput)"
                      aria-label="clear search"
                      data-automation-id="clear-search-btn">
                <span aria-hidden="true" class="icon-close"></span>
            </button>
    <p class="search-input-no-matches" *ngIf="noMatchesFound">{{'noMatchesFound' | translate}}</p>
  </div>
  <div class="search-container__filters">
    <app-custom-dropdown class="search-dropdown"
                        data-automation-id="search-bar-dropdown"
                        [overlayOffsetX]="0"
                        cdkMonitorElementFocus
                        [selectedOption]="searchType"
                        (change)="onSearchTypeChange($event)"
                        [aria-label]="'searchBy' | translate">
      <app-custom-dropdown-option
        *ngFor="let type of searchTypes"
        class="search-dropdown-option"
        [class.search-bar-dropdown-option-small]="false"
        [value]="type.id"
        [id]="type.id"
        translate>{{ type.label }}
      </app-custom-dropdown-option>
    </app-custom-dropdown>
    <div class="availability-filter-toggle align-self-start ml-auto">
      <button (click)="toggleAvailability(SearchAvailabilityFilter.All)"
              [disabled]="lockAvailabilityFilter"
              [class.active]="availabilityFilter === SearchAvailabilityFilter.All"
              [attr.aria-pressed]="availabilityFilter === SearchAvailabilityFilter.All"
              data-automation-id="availability-filter-toggle-all-button"
              class="mr-0">
              <fa-icon [icon]="faObjectsColumn"></fa-icon>
        <span class="toggle-label-text">{{'All' | translate}}</span>
      </button>
      <button (click)="toggleAvailability(SearchAvailabilityFilter.Available)"
              [class.active]="availabilityFilter === SearchAvailabilityFilter.Available"
              [attr.aria-pressed]="availabilityFilter === SearchAvailabilityFilter.Available"
              data-automation-id="availability-filter-toggle-available-button" class="ml-0.5">
              <fa-icon [icon]="faSquareCheck"></fa-icon>
        <span class="toggle-label-text">{{'searchCardAvailable' | translate}}</span>
      </button>
    </div>
  </div>
</div>
