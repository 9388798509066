import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faConveyorBeltAlt, faCopy, faEdit, faEnvelope, faTrash } from '@fortawesome/pro-light-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  BookshelfCardActionStateTypeEnum
} from 'common/components/bookshelf-card-action-state/bookshelf-card-action-state.component';
import { BookshelfCardType } from 'common/components/bookshelf-card-status/bookshelf-card-status.component';
import {
  BookshelfCardActionPayload,
  BookshelfCardShareActionType,
  SavedSearchCardActionType
} from 'common/models/bookshelf-card';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { areEqualDeeply } from 'shared/utils/are-equal-deeply';
import { openShowcasesTab } from 'user/actions/user-profile.actions';
import { UserPermission } from 'user/models/user';
import { doesUserHavePermission } from 'user/reducers/user.reducer';
import { hideForm, openShowcase, showForm } from '../../../custom-showcase/actions/custom-showcase.actions';
import { CustomShowcase, CustomShowcaseCreatedFromType } from '../../../custom-showcase/models/custom-showcase';
import { getMyShowcase } from '../../../custom-showcase/reducers/custom-showcase.reducer';
import { CopyToClipboardService } from '../../../services/copy-to-clipboard.service';
import {
  copySavedSearch,
  openEmailModal,
  resetGetSavedSearch,
  resetSavedSearchRunState,
  setLastCopied,
  shareOnFacebook,
  shareOnTwitter,
  stopFocusShareButton
} from '../../actions/saved-search.actions';
import { SavedSearch } from '../../models/saved-search';
import { getFocusShareButton, getGetState, getRunState, SavedSearchState } from '../../reducers/saved-search.reducer';
import { DeleteSavedSearchModalComponent } from '../delete-saved-search-modal/delete-saved-search-modal.component';
import { EditSearchNameModalComponent } from '../edit-search-name-modal/edit-search-name-modal.component';
import { SitePermissions } from 'app/permissions/models/permissions';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { faXTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { DiscoverV2BffService } from 'app/elements-v2/service/discover-v2-bff.service';

enum DisplayState {
  NONE,
  LOADING,
  RUN_FAILED,
  SHARE_FAILED,
  SHARE_COPIED,
}

@Component({
  selector: 'app-saved-search-card',
  templateUrl: './saved-search-card.component.html',
  styleUrls: ['./saved-search-card.component.scss']
})
export class SavedSearchCardComponent implements OnInit, OnDestroy {
  @Input() public savedSearch: SavedSearch;
  @Output() public readonly onRunSavedSearch: EventEmitter<string> = new EventEmitter();

  public ariaTitleId: string;
  public displayState: DisplayState = DisplayState.NONE;
  public relatedShowcase?: CustomShowcase;
  public sitePermissions = SitePermissions;
  public isAuthPatronFlagEnabled: boolean;
  public bookshelfShowShareIcon: boolean;

  public readonly focus$: Observable<void>;
  public readonly focusActionsMenuToggleButton$: Observable<void>;
  public readonly focusShareMenuToggleButton$: Observable<void>;
  public readonly bookshelfCardType = BookshelfCardType;
  public readonly hasCreateShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_CREATE));
  public readonly hasViewShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_VIEW));

  public readonly displayStateEnum = DisplayState;
  public readonly cardActionTypeEnum = SavedSearchCardActionType;
  public readonly cardShareActionTypeEnum = BookshelfCardShareActionType;
  public readonly cardActionStateTypeEnum = BookshelfCardActionStateTypeEnum;
  public readonly editIcon = faEdit;
  public readonly trashIcon = faTrash;
  public readonly copyIcon = faCopy;
  public readonly emailIcon = faEnvelope;
  public readonly showcaseIcon = faConveyorBeltAlt;
  public readonly faXTwitter = faXTwitter;
  public readonly faFacebook = faFacebook;
  private readonly focusSubject = new Subject<void>();
  private readonly focusActionsMenuToggleButtonSubject = new Subject<void>();
  private readonly focusShareMenuToggleButtonSubject = new Subject<void>();
  private readonly subscriptions = new Subscription();

  constructor(
    private readonly modal: NgbModal,
    private readonly store: Store<SavedSearchState>,
    private readonly copyService: CopyToClipboardService,
    private readonly featureToggleService: FeatureToggleService,
    private discoverV2BffService: DiscoverV2BffService
  ) {
    this.focusActionsMenuToggleButton$ = this.focusActionsMenuToggleButtonSubject.asObservable();
    this.focusShareMenuToggleButton$ = this.focusShareMenuToggleButtonSubject.asObservable();
    this.focus$ = this.focusSubject.asObservable();
  }

  public ngOnInit(): void {
    this.ariaTitleId = `saved-search-${this.savedSearch.id}`;
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
    this.subscriptions.add(this.discoverV2BffService.$page.subscribe((page) => {
      if (page) {
        this.bookshelfShowShareIcon = page.settings?.social_media_display?.my_bookshelf &&
          this.featureToggleService.getToggles()['DIS-30364_2024-06-30_Social-Media-Share'];
      }
    }));
    this.subscriptions.add(
      this.store.select(getGetState, { id: this.savedSearch.id })
        .pipe(distinctUntilChanged(this.areEqualStates))
        .subscribe((getState) => {
          this.displayState = DisplayState.NONE;

          if (getState) {
            if (getState.error) {
              this.displayState = DisplayState.SHARE_FAILED;
            } else if (getState.loading) {
              this.displayState = DisplayState.LOADING;
            } else if (getState.url) {
              this.copyToClipboard(getState.url);
              this.displayState = DisplayState.SHARE_COPIED;
              this.focusShareMenuToggleButtonSubject.next();
              this.store.dispatch(setLastCopied({ id: this.savedSearch.id }));
            } else if (getState.lastCopied) {
              this.displayState = DisplayState.SHARE_COPIED;
            }
          }
        })
    );

    this.subscriptions.add(
      this.store.select(getRunState)
        .pipe(distinctUntilChanged(this.areEqualStates))
        .subscribe((runState) => {
          if (runState && this.savedSearch.id === runState.id) {
            if (runState.error) {
              this.displayState = DisplayState.RUN_FAILED;
            }

            if (runState.loading) {
              this.displayState = DisplayState.LOADING;
            }
          } else {
            this.displayState = DisplayState.NONE;
          }
        })
    );

    this.subscriptions.add(
      this.store.select(getFocusShareButton).subscribe((focusShareButton) => {
        if (focusShareButton && focusShareButton === this.savedSearch.id) {
          this.store.dispatch(stopFocusShareButton());
          this.focusShareMenuToggleButtonSubject.next();
        }
      })
    );

    if (this.savedSearch.showcaseRef) {
      this.subscriptions.add(
        this.store.select(getMyShowcase, { id: this.savedSearch.showcaseRef })
          .subscribe((showcase) => {
            this.relatedShowcase = showcase;
          })
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.focusSubject.complete();
    this.focusActionsMenuToggleButtonSubject.complete();
    this.focusShareMenuToggleButtonSubject.complete();
  }

  public triggerCardAction(actionPayload: BookshelfCardActionPayload<SavedSearchCardActionType>) {
    switch (actionPayload.actionType) {
      case SavedSearchCardActionType.edit: {
        this.onEditAction();
        break;
      }
      case SavedSearchCardActionType.createShowcase: {
        this.onCreateShowcaseAction();
        break;
      }
      case SavedSearchCardActionType.viewShowcase: {
        this.viewShowcase();
        break;
      }
      case SavedSearchCardActionType.delete: {
        this.onDeleteAction();
        break;
      }
    }
  }

  public onEditAction() {
    const modalRef = this.modal.open(
      EditSearchNameModalComponent,
      {
        windowClass: 'inspire-custom-modal',
        ariaLabelledBy: 'edit-search-name-modal-title'
      }
    );
    modalRef.componentInstance.savedSearch = this.savedSearch;
    modalRef.result.finally(() => {
      this.focusActionsMenuToggleButtonSubject.next();
    });
  }

  public onCreateShowcaseAction() {
    const createdFrom = {
      id: this.savedSearch.id,
      name: this.savedSearch.name,
      type: CustomShowcaseCreatedFromType.savedSearch
    };
    this.store.dispatch(showForm({ showcase: { createdFrom } }));
  }

  public onDeleteAction() {
    const modalRef = this.modal.open(DeleteSavedSearchModalComponent,
      {
        windowClass: 'inspire-custom-modal',
        ariaLabelledBy: 'delete-saved-search-modal-title',
        ariaDescribedBy: 'delete-saved-search-caution',
        beforeDismiss: () => {
          this.focusActionsMenuToggleButtonSubject.next();
          return true;
        }
      });
    modalRef.componentInstance.search = this.savedSearch;
  }

  public viewShowcase() {
    this.store.dispatch(hideForm());
    this.store.dispatch(openShowcasesTab());
    this.store.dispatch(openShowcase({ id: this.savedSearch.showcaseRef }));
  }

  public triggerCardShareAction(actionPayload: BookshelfCardActionPayload<BookshelfCardShareActionType, undefined>) {

    const image =
      this.savedSearch.coverConfigs.find((config) => config?.coverConfig?.coverUrl['large'] );
    const data = {
      id: this.savedSearch.id,
      title: this.savedSearch.name,
      image: image ? image.coverConfig.coverUrl['large']
                  || image.coverConfig.coverUrl['medium']
                  || image.coverConfig.coverUrl['small'] : '',
    };

    switch (actionPayload.actionType) {
      case BookshelfCardShareActionType.copyLink: {
        this.store.dispatch(copySavedSearch({ id: this.savedSearch.id }));
        break;
      }
      case BookshelfCardShareActionType.email: {
        this.store.dispatch(openEmailModal({ id: this.savedSearch.id }));
        break;
      }
      case BookshelfCardShareActionType.facebook: {
        this.store.dispatch(shareOnFacebook(data));
        break;
      }
      case BookshelfCardShareActionType.twitter: {
        this.store.dispatch(shareOnTwitter(data));
        break;
      }
    }
  }

  public runSavedSearch() {
    this.onRunSavedSearch.emit(this.savedSearch.id);
  }

  public clearStatus(): void {
    this.store.dispatch(resetGetSavedSearch());
    this.store.dispatch(resetSavedSearchRunState());
  }

  public focus() {
    this.focusSubject.next();
  }

  public areEqualStates(oldState: any, newState: any) {
    if (!oldState || !newState) {
      return false;
    }
    return areEqualDeeply(oldState, newState);
  }

  private copyToClipboard(text: string): void {
    this.copyService.copyToClipboard(text);
  }
}
