<div *ngIf="hasValidPickupAreas" class="pickup-area form-group">
  <label
    id="choose-pickup-area-label"
    for="choose-pickup-area"
    data-automation-id="choose-pickup-area-label"
    class="pickup-area__label">
    {{ 'choosePickupArea' | translate }}
  </label>
  <select class="form-control pickup-area__select select-custom-arrow"
          id="choose-pickup-area"
          [(ngModel)]="selectedPickupAreaCode"
          (change)="onPickupAreaChange($event.target.value)"
          [disabled]="disabled"
          [attr.data-automation-id]="dataAutomationId"
          aria-labelledby="choose-pickup-area-label">
    <option  *ngFor="let area of availablePickupArea" [value]="area.code" data-automation-id="choose-pickup-area-option">
      <ng-container *ngIf="area.code">
        {{ 'pickup-area.' + area.code | translate }}
      </ng-container>
      <ng-container *ngIf="!area.code">
        {{ area.description }}
      </ng-container>
    </option>
  </select>
</div>
