<ng-container>
  <ng-container *ngIf="loading; else portal">
    <span translate>Loading...</span>
  </ng-container>
  <ng-template #portal>
    <account-portal-custom-element [translations]="translations">
      <span class="d-none">{{
        {
          user,
          staffUser,
          portalFeaturedShowcaseRef,
          portalFeaturedShowcaseTitle,
          portalFallbackShowcaseRef,
          checkoutsCount,
          holdsCount,
          bookmarksList,
          forLaterList,
          customer,
          config,
          translations,
          flags
        } | json
      }}</span>
    </account-portal-custom-element>
  </ng-template>
</ng-container>
