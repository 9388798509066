import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FacetResult, ResponseFacet } from 'search/facets/models/resource-facet';
import { FacetsService } from 'search/facets/services/facets.service';
import { ResourceFacetBlock } from 'search/models/filter-panel';
import { FacetSearchObject } from 'search/models/search-object';
import { AvailabilityStatus, FormatGroup, MaterialTab } from '../../entity/models/entity';
import { LibraryList } from '../../library-list/reducers/library-list.reducer';
import {
  BookmarksSearchFacets,
  BookmarksSearchType,
  ListCreate,
  ListItem,
  ListItemEntity,
  ListUpdate,
  ListWithItemsCount,
} from '../models/list';
import {
  ListItemDto,
  ListItemsDto,
  ListPaginationParams,
  ListPatchItemsRequest,
  ListPatchItemsResponse,
  ListSortByString
} from '../models/list.dto';
import { ListItemWithIcons } from '../components/searchable-bookmarks-results-preview/searchable-bookmarks-results-preview.component';
import { AvailabilityStatusToFilterMap, MATERIAL_ICONS } from 'app/models/material-icons';
import { DictionaryItem, DictionaryTypes } from 'app/models/dictionaries';
import { DictionariesService } from 'app/services/dictionaries.service';
import { BookmarkSearch } from '../components/searchable-bookmarks-search-bar/searchable-bookmarks-search-bar.component';

export type ListItemsDtoWithoutSortBy = Omit<ListItemsDto, 'sortedBy'>;

type Icon = {
  url: string;
  filter: string;
  label: string;
  formatGroup?: string;
}

@Injectable()
export class ListService {
  private static readonly listUrl = 'api/search-result/personalization/lists';

  constructor(
    private readonly http: HttpClient,
    private facetsService: FacetsService,
    private readonly dictionariesService: DictionariesService,
  ) {
  }

  public createList(name: string, items: ListItemDto[] = []): Observable<ListCreate> {
    return this.http
    .post<{ id: string }>(ListService.listUrl, {name, items}, {headers: { 'api-version': '2' }})
    .pipe(map(({id}) => ({id})));
  }

  public updateList(request: ListUpdate): Observable<ListUpdate> {
    return this.http
    .patch<void>(`${ListService.listUrl}/${request.id}/rename`, request, {headers: { 'api-version': '2' }})
    .pipe(map(() => request));
  }

  public getItems(
    id: string,
    sortBy: ListSortByString,
    paginationParams: ListPaginationParams,
    filterHiddenShowcaseItems = false,
  ): Observable<ListItemsDtoWithoutSortBy> {
    return this.http.get<ListItemsDtoWithoutSortBy>(`${ListService.listUrl}/${id}/items`, {
      params: {
        sortBy,
        pageNum: paginationParams.pageNum.toString(),
        pageSize: paginationParams.pageSize.toString(),
        filterHiddenShowcaseItems,
      },
      headers: { 'api-version': '2' }
    });
  }

  public patchItems(body: ListPatchItemsRequest): Observable<ListPatchItemsResponse> {
    if (!body.addTo.length && !body.deleteFrom.length) {
      return of({addedTo: [], deletedFrom: [], notFound: []});
    }
    return this.http.patch<ListPatchItemsResponse>(`${ListService.listUrl}/items`, body, {
      headers: { 'api-version': '2' }
    });
  }

  public makePatchItem(entity: ListItemEntity): ListItemDto {
    return {
      id: entity.id,
      entityType: entity.entityType,
      title: entity.title,
      recordId: entity.selectedTabRecordId,
    };
  }

  public deleteList(id: string): Observable<string> {
    return this.http
    .delete<void>(`${ListService.listUrl}/${id}`, {headers: { 'api-version': '2' }})
    .pipe(map(() => id));
  }

  public getList(id: string): Observable<LibraryList> {
    return this.http.get<LibraryList>(`${ListService.listUrl}/${id}`, {headers: {'api-version': '2'}});
  }

  public findMatches(list: ListWithItemsCount, query: BookmarkSearch, facets: BookmarksSearchFacets) {
    const regEx = '/[^A-Z0-9]/ig';
    const text = query.text.toLowerCase().replace(regEx, '');
    const type = query.type ?? BookmarksSearchType.Author;
    let matchingCodes: string[] = [];

    if (text) {
      list.items.forEach(item => item.matched = false);
      list.items.forEach(item => {
        const fg = item.entity.sourceEntity as FormatGroup;
        const materialTabs = fg.materialTabs ?? [];
        switch (type) {
          case BookmarksSearchType.Format:
            matchingCodes = this.findMatchingIds(text, facets.format);
            for (const m of materialTabs) {
              const materialTypes = m.materialTypes ?? [];
              for (const t of materialTypes) {
                if (matchingCodes.indexOf(t) > -1) {
                  item.matched = true;
                  break;
                }
              }
            }
            break;
          case BookmarksSearchType.Language:
            matchingCodes = this.findMatchingIds(text, facets.language);
              if (matchingCodes.indexOf(fg.language) > -1) {
                item.matched = true;
                break;
              }
            break;
          case BookmarksSearchType.Location:
            matchingCodes = this.findMatchingIds(text, facets.language);
            for (const m of materialTabs) {
              const locations = m.locations ?? [];
              for (const l of locations) {
                if (matchingCodes.indexOf(l.code)) {
                  item.matched = true;
                  break;
                }
              }
            }
            break;
          case BookmarksSearchType.Title:
            if (fg.title.toLowerCase().replace(regEx, '').includes(text)) {
              item.matched = true;
              break;
            }
            break;
          case BookmarksSearchType.Author:
            if (fg.primaryAgent?.label?.toLowerCase().replace(regEx, '').includes(text)) {
              item.matched = true;
              break;
            }
            break;
          case BookmarksSearchType.DateFrom:
            if (parseInt(fg.publicationDate)) {
              const dates = fg.publicationDate.split('-');
              for (const d of dates) {
                if (parseInt(d) === parseInt(text)) {
                  item.matched = true;
                  break;
                }
              }
            }
            break;
          case BookmarksSearchType.Series:
            if (fg.seriesTitle?.toLowerCase().replace(regEx, '').includes(text)
              || fg.seriesTitleSearch?.toLowerCase().replace(regEx, '').includes(text)) {
              item.matched = true;
              break;
            }
            break;
          case BookmarksSearchType.Concept:
            if (fg.title.toLowerCase().replace(regEx, '').includes(text)) {
              item.matched = true;
              break;
            }
            break;
        }
    });
    } else {
      list.items.forEach(item => item.matched = true);
    };

    if (query.availableOnly) {
      for (const item of list.items) {
        const fg = item.entity.sourceEntity as FormatGroup;
        const materialTabs = fg.materialTabs ?? [];
        const statuses = [];
        if (item.matched) {
          for (const m of materialTabs) {
            statuses.push(m?.availability?.status?.general);
          }
          if (statuses.indexOf(AvailabilityStatus.AVAILABLE) < 0) {
            item.matched = false;
          }
        }
      }
    }
  }

  private findMatchingIds(searchText: string, facet: FacetResult<ResponseFacet>) {
    const regEx = '/[^A-Z0-9]/ig';
    const text = searchText.toLowerCase().replace(regEx, '');
    const ids = facet.data.filter(value => value.label.toLowerCase().replace(regEx, '').includes(text)).map(value => value.id);
    return ids;
  }

  public getFacets(): Observable<FacetResult<ResponseFacet>[]> {
    return forkJoin(this.getFormats(), this.getLocations(), this.getLanguages());
  }

  public getFormats(): Observable<FacetResult<ResponseFacet>> {
    const query: FacetSearchObject = {
      facetKey: ResourceFacetBlock.FORMATS,
      facetMapField: 'MaterialType',
    };
    const options = {
      pageNum: 0,
      pageSize: 100
    };
    return this.facetsService.getSingleFacet(query, options);
  }

  public getLocations(): Observable<FacetResult<ResponseFacet>> {
    const query: FacetSearchObject = {
      facetKey: ResourceFacetBlock.LOCATION,
      facetMapField: 'Location',
    };
    const options = {
      pageNum: 0,
      pageSize: 100
    };
    return this.facetsService.getSingleFacet(query, options);
  }

  public getLanguages(): Observable<FacetResult<ResponseFacet>> {
    const query: FacetSearchObject = {
      facetKey: ResourceFacetBlock.LANGUAGE,
      facetMapField: 'Language',
    };
    const options = {
      pageNum: 0,
      pageSize: 100
    };
    return this.facetsService.getSingleFacet(query, options);
  }

  private getIcons(materialTabs: MaterialTab[]) {
    const icons: Icon[] = [];
    materialTabs?.slice(0, 4).forEach((m, index) => {
      const code = m.materialTypes?.[0];
      const item: DictionaryItem = this.dictionariesService.getDictionaryItemByCode(
        DictionaryTypes.MATERIAL_TYPES,
        code
      );
      const iconKey = materialTabs.length > 4 && index === 3
        ? 'default-other'
        : item?.icon ?? 'default-other';
      const iconDetails = MATERIAL_ICONS[iconKey as string];
      const url = iconDetails?.icon || '';
      const label = iconDetails?.label || '';
      const formatGroup = item?.formatGroup;
      const availabilityStatus = m.availability.status.general ?? AvailabilityStatus.UNAVAILABLE;
      const filter = AvailabilityStatusToFilterMap[availabilityStatus];
      icons.push({ url, filter, label, formatGroup });
    });
    return icons;
  }

  public generateSearchPreview(lists: ListWithItemsCount[]) {
    const preview: ListItem[] = [];
    const limit = 6;
    for (const list of lists) {
      if (preview.length >= limit) break;
      for (const item of list.items) {
        let hasAvailableMaterialType = false;
        const sourceEntity = item?.entity?.sourceEntity;
        if (!sourceEntity) continue;
        const materialTabs = (sourceEntity as FormatGroup).materialTabs;
        for (const materialTab of materialTabs) {
          const status = materialTab?.availability?.status?.general;
          if (!status) continue;

          if (status == AvailabilityStatus.AVAILABLE || status == AvailabilityStatus.CHECK_AVAILABILITY) {
            hasAvailableMaterialType = true;
            break;
          }
        }
        if (hasAvailableMaterialType) {
          preview.push(item);
          if (preview.length >= limit) break;
        }
      }
    }
    return preview.map(item => {
      (item as ListItemWithIcons).icons = this.getIcons((item.entity.sourceEntity as FormatGroup).materialTabs);
      return item;
    });
  }
}
