import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faAngleLeft, faBookmark as bookmarkIcon } from '@fortawesome/pro-regular-svg-icons';
import { faCartShoppingFast, faConveyorBeltAlt } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { CustomerFeature } from 'app/customer-integration/customer-integration';
import { Subscription } from 'rxjs';
import { tryToBulkHoldSelectedItems } from '../../../bulk-select/actions/bulk-select.actions';
import { BulkFeatureKey } from '../../../bulk-select/enums/bulk-feature-key';
import { SitePermissions } from '../../../permissions/models/permissions';
import { loadMoreListItems, moveBookmarks, openList } from '../../actions/list.actions';
import {
  BookmarksSearchFacets,
  ListItem,
  ListItemEntity,
  ListWithItemsCount,
  SearchAvailabilityFilter
} from '../../models/list';
import { ListService } from '../../services/list.service';
import { getOpenedList, getSearchFacets, isLastPageLoaded } from 'app/list/reducers/list.reducer';
import { BookmarkSearch } from '../searchable-bookmarks-search-bar/searchable-bookmarks-search-bar.component';

@Component({
  selector: 'app-searchable-bookmarks-available-list',
  templateUrl: './searchable-bookmarks-available-list.component.html',
  styleUrls: ['./searchable-bookmarks-available-list.component.scss']
})
export class SearchableBookmarksAvailableListComponent implements OnInit, OnDestroy {
  @Input() public lists: ListWithItemsCount[];
  @Input() public authorized: boolean;
  @Output() public closeList = new EventEmitter();
  public itemsEntitySelected: ListItemEntity[];
  public itemsSelectedCount: number = 0;
  public isAllSelected: boolean = false;

  // icons
  public showcaseIcon = faConveyorBeltAlt;
  public placeHoldsIcon = faCartShoppingFast;
  public readonly arrowIconLeft = faAngleLeft;
  public readonly bookmarkIcon = bookmarkIcon;

  // view data - search
  public allLists: ListWithItemsCount[] = [];
  public list: ListWithItemsCount = {
    items: [],
  } as ListWithItemsCount;
  public itemsSearch: ListItem[] = [];
  public loading: boolean = false;
  public facets: BookmarksSearchFacets;
  public listInitialized: boolean;
  public latestSearch: BookmarkSearch;
  public listsToSearch: string[] = [];

  // constants
  public sitePermissions = SitePermissions;
  public readonly bulkFeatureKey = BulkFeatureKey.BOOKMARKS;
  public readonly CustomerFeature = CustomerFeature;
  public availabilityFilter: SearchAvailabilityFilter = SearchAvailabilityFilter.Available;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly listService: ListService,
  ) {
  }

  ngOnInit() {
    this.subscriptions.add(this.store.select(getOpenedList).subscribe(openedList => {
      if (!openedList) {
        return;
      }
      this.getList(openedList);
    }));
    this.subscriptions.add(this.store.select(getSearchFacets).subscribe(facets => this.facets = facets));
    this.onSearch({text: ''} as BookmarkSearch);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onSearch(search: BookmarkSearch) {
    this.latestSearch = search;
    this.loading = true;
    if (!this.listInitialized) {
      this.listsToSearch = this.lists.map(({id}) => id);
      this.getNext();
    } else {
      this.listService.findMatches(this.list, this.latestSearch, this.facets);
      this.itemsSearch = this.list.items.filter(i => i.matched);
      this.loading = false;
    }
  }

  public getList(openedList: ListWithItemsCount) {
    if (this.allLists.find(l => l.id === openedList.id)) {
      return;
    }
    if (!openedList.itemsLoadingState.loading && !isLastPageLoaded(openedList)) {
      this.store.dispatch(loadMoreListItems({id: openedList.id}));
      return;
    }
    this.listService.findMatches(openedList, {text: ''} as BookmarkSearch, this.facets);
    const openedListSearch = {...openedList, items: openedList.items.filter(i => i.matched)};
    openedListSearch.itemsCount = openedListSearch.items.length;
    this.allLists.push(openedListSearch);
    this.list.items.push(...openedListSearch.items);
    this.getNext();
  }

  public getNext() {
    if (this.listsToSearch.length) {
      const list = this.listsToSearch.shift();
      this.store.dispatch(openList({listId: list}));
    } else {
      this.listInitialized = true;
      this.listService.findMatches(this.list, this.latestSearch, this.facets);
      this.itemsSearch = this.list.items.filter(i => i.matched);
      this.loading = false;
    }
  }

  public setAllItemsSelection(listId: string, selected: boolean): void {
    this.isAllSelected = selected;
    if (this.isAllSelected) {
      this.list.items.forEach(item => {
        item.selected = this.isAllSelected;
      });
    }
    this.list.items.forEach(item => item.selected = this.isAllSelected);
    this.updateSelected();
  }

  public updateSelected() {
    this.itemsEntitySelected = this.list.items.filter((item) => item.selected).map((item) => item.entity);
    this.itemsSelectedCount = this.itemsEntitySelected.length;
  }

  public onToggleItem(listId: string, listItem: ListItem): void {
    const item = this.list.items.find(item => item.id === listItem.id);
    if (item) {
      item.selected = !item.selected;
    }
    this.updateSelected();
  }

  public removeSelectedFromList(): void {
    const items = this.itemsEntitySelected.map((item) => item);
    this.store.dispatch(moveBookmarks({entities: items, fromListIds: ['available-list']}));
  }

  public bulkPlaceHolds(event: Event) {
    event.stopPropagation();
    if (this.itemsEntitySelected.length) {
      const items = this.itemsEntitySelected.map((item) => item.sourceEntity);
      this.store.dispatch(tryToBulkHoldSelectedItems({bulkFeatureKey: this.bulkFeatureKey, items}));
    }
  }
}
