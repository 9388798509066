import { Injectable } from '@angular/core';
import { ScriptLoaderService } from 'common/services/script-loader.service';
import { StyleLoaderService } from 'common/services/style-loader.service';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { WindowRefService } from '../../services/window-ref.service';
import { SiteTheme } from '../models/settings';

@Injectable({
  providedIn: 'root',
})
export class ElementsV2Service {
  private readonly baseURL = `${this.windowRefService.origin()}/discover-admin-path`;

  private readonly homePageScript = `${this.baseURL}/preview-custom-element.js`;
  private readonly headerAnnouncementScript = `${this.baseURL}/header-announcement-custom-element.js`;
  private readonly gridViewScript = `${this.baseURL}/gridview-custom-element.js`;
  private readonly monsterHelpScript = `${this.baseURL}/monster-custom-element.js`;
  private readonly accountPortalScript = `${this.baseURL}/account-portal-custom-element.js`;

  private readonly SteamPunkTheme = 'steam-punk';
  private readonly ComicTheme = 'comic';
  private readonly VegaModernTheme = 'vega-modern';
  private readonly VegaClassicV2Theme = 'vega-classic-v2';
  private readonly VegaClassicTheme = 'vega-classic';

  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly scriptLoaderService: ScriptLoaderService,
    private readonly styleLoaderService: StyleLoaderService,
    private readonly featureToggleService: FeatureToggleService
  ) {
  }

  public async loadScripts() {
    await this.loadHomePageScript();
    await this.loadHeaderAnnouncementScript();
    await this.loadGridViewScript();
    await this.loadMonsterHelpScript();
    await this.loadAccountPortalScript();
  }

  public async loadStyle(THEME: SiteTheme, color: string = 'white') {
    switch (THEME) {
      case SiteTheme.VEGA_CLASSIC: {
        await this.loadDefault(color);
        break;
      }
      case SiteTheme.COMIC: {
        await this.loadComic(color);
        break;
      }
      case SiteTheme.STEAM_PUNK: {
        await this.loadSteamPunk(color);
        break;
      }
      case SiteTheme.VEGA_MODERN: {
        await this.loadVegaModern(color);
        break;
      }
      case SiteTheme.VEGA_CLASSIC_V2: {
        await this.loadClassicV2('');
        break;
      }
    }
  }

  public stabilizeURL(base: string, name: string, color?: string): string {
    return (color ? `${base}/${name}-${color}` : `${base}/${name}`) + '.css';
  }

  private async loadClassicV2(color?: string): Promise<void> {
    return this.styleLoaderService.loadStyle(
      this.stabilizeURL(this.baseURL, this.VegaClassicV2Theme, color)
    );
  }

  private async loadVegaModern(color?: string): Promise<void> {
    return this.styleLoaderService.loadStyle(
      this.stabilizeURL(this.baseURL, this.VegaModernTheme, color)
    );
  }

  private async loadDefault(color:string): Promise<void> {
    return this.styleLoaderService.loadStyle(
      this.stabilizeURL(this.baseURL, this.VegaClassicTheme, color)
    );
  }

  private async loadComic(color?: string): Promise<void> {
    return this.styleLoaderService.loadStyle(
      this.stabilizeURL(this.baseURL, this.ComicTheme, color)
    );
  }

  private async loadSteamPunk(color?: string): Promise<void> {
    return this.styleLoaderService.loadStyle(
      this.stabilizeURL(this.baseURL, this.SteamPunkTheme, color)
    );
  }

  private async loadHomePageScript(): Promise<void> {
    return this.scriptLoaderService.loadScript({src: this.homePageScript});
  }

  private async loadHeaderAnnouncementScript(): Promise<void> {
    if (
      this.featureToggleService.getToggles()['VE-2814_2023-09-30_announcements']
      ||
      this.featureToggleService.getToggles()[
        'VE-3646_2023-12-31_staff_announcements'
      ]
    ) {
      return this.scriptLoaderService.loadScript({
        src: this.headerAnnouncementScript,
      });
    }
  }

  private async loadGridViewScript(): Promise<void> {
    return this.scriptLoaderService.loadScript({src: this.gridViewScript});
  }

  private async loadMonsterHelpScript(): Promise<void> {
    return this.scriptLoaderService.loadScript({src: this.monsterHelpScript});
  }

  private async loadAccountPortalScript(): Promise<void> {
    return this.scriptLoaderService.loadScript({src: this.accountPortalScript});
  }
}
