import {
  Injectable,
  Inject,
  LOCALE_ID,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class I18NService {
  private _translations: { [key: string]: string };
  private _languageMap = new Map<string, string>();
  private _formatMap = new Map<string, string>();
  private _locationMap = new Map<string, string>();
  private readonly _contributorMap = new Map<string, string>();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private translateService: TranslateService,
  ) {}

  public init() {
    return this.translateService.use(this.locale).pipe(
      tap((translations) => {
        this._translations = translations;

        for (const key in translations) {
          if (key.startsWith('lang|')) {
            this._languageMap.set(key.slice(5).toLowerCase(), translations[key]);
          } else if (key.startsWith('contributor|')) {
            this._contributorMap.set(key.slice(12).toLowerCase(), translations[key]);
          } else if (key.startsWith('material-type.')) {
            this._formatMap.set(key.slice(14).toLowerCase(), translations[key]);
          } else if (key.startsWith('location.')) {
            this._locationMap.set(key.slice(9).toLowerCase(), translations[key]);
          }
        }
      }),
    ).toPromise();
  }

  public getLangFacetTitle(key: string): string {
    if (key) {
      const title = this._languageMap.get(key.toLowerCase());
      return title ? title : `${this._languageMap.get('unknown')} (${key})`;
    }

    return this._languageMap.get('unknown');
  }

  public getFormatFacetTitle(key: string): string {
    if (key) {
      const title = this._formatMap.get(key.toLowerCase());
      return title ? title : `${this._formatMap.get('unknown')} (${key})`;
    }

    return this._formatMap.get('unknown');
  }

  public getLocationFacetTitle(key: string): string {
    if (key) {
      const title = this._locationMap.get(key.toLowerCase());
      return title ? title : `${this._locationMap.get('unknown')} (${key})`;
    }

    return this._locationMap.get('unknown');
  }

  public getContributorRole(key: string, isShowcases?: boolean): string {
    const title = this._contributorMap.get(key.toLowerCase());
    if (isShowcases) {
      return title && key.toLowerCase() !== 'none' ? title : null;
    }
    return title ? title : key;
  }

  public getContributorCodeByDesc(query: string) {
    const preparedQuery = query.toLowerCase();
    let result = '';
    this._contributorMap.forEach((desc, code) => {
      if (desc.toLowerCase() === preparedQuery) {
        result = code;
      }
    });
    return result;
  }

  public getLangCodesByDesc(query: string) {
    const codes: string[] = [];
    const preparedQuery = query.toLowerCase();
    this._languageMap.forEach((desc, code) => {
      if (desc.toLowerCase().includes(preparedQuery)) {
        codes.push(code);
      }
    });
    return codes;
  }

  public mapCodesDescriptionsForRoles(roles: string[]) {
    const rolesMapped = roles.map((relationship: string) => {
      const foundDescription = this.getContributorRole(relationship, true);
      const foundCode = this.getContributorCodeByDesc(relationship);
      const description = foundDescription ||
        (foundCode ? this.getContributorRole(foundCode, true) : '');
      const code = foundDescription ? relationship : foundCode;
      return { description, code };
    });

    return rolesMapped.filter((val, idx, arr) => {
      return arr.findIndex(val2 => (val2.description === val.description)) === idx;
    });
  }

  public getTranslation(key: string) {
    return this._translations?.[key];
  }

  public isEnglishLanguage() {
    return this.translateService.currentLang === 'en-US';
  }
}
