import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownValue } from 'common/models/custom-dropdown';
import { FacetResult, ResponseFacet } from 'search/facets/models/resource-facet';
import {
  SearchAvailabilityFilter,
  BookmarksSearchType,
  BookmarksSearchTypes,
  BookmarksSearchFacets,
} from '../../models/list';
import { faObjectsColumn, faSquareCheck } from '@fortawesome/pro-light-svg-icons';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getSearchFacets } from '../../reducers/list.reducer';


export type BookmarkSearch = {
  text: string;
  type: string;
  availableOnly: boolean;
}

@Component({
  selector: 'app-searchable-bookmarks-search-bar',
  templateUrl: './searchable-bookmarks-search-bar.component.html',
  styleUrls: ['./searchable-bookmarks-search-bar.component.scss']
})
export class SearchableBookmarksSearchBarComponent implements OnInit {
  @Input() public availabilityFilter: SearchAvailabilityFilter = SearchAvailabilityFilter.All;
  @Input() public lockAvailabilityFilter: boolean = false;
  @Output() public search = new EventEmitter<BookmarkSearch>();
  public noMatchesFound: boolean = false;
  public searchTypes = BookmarksSearchTypes;
  public searchType: BookmarksSearchType = BookmarksSearchType.Author;
  protected readonly SearchAvailabilityFilter = SearchAvailabilityFilter;
  public searchText = '';
  public faObjectsColumn = faObjectsColumn;
  public faSquareCheck = faSquareCheck;
  public facets: BookmarksSearchFacets;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.store.select(getSearchFacets).subscribe(facets => this.facets = facets));
  }

  public onKeydownEnter(value: string) {
    if (value === this.searchText) return;
    this.searchText = value;
    if (!this.checkMatches()) return;
    this.search.emit({
      text: this.searchText,
      type: this.searchType,
      availableOnly: this.availabilityFilter === this.SearchAvailabilityFilter.Available,
    });
  }

  public clearSearchText(inputElement: HTMLInputElement) {
    inputElement.value = '';
    this.searchText = '';
    this.noMatchesFound = false;
    this.search.emit({
      text: this.searchText,
      type: this.searchType,
      availableOnly: this.availabilityFilter === this.SearchAvailabilityFilter.Available,
    });
  }

  public onSearchTypeChange(event: DropdownValue<BookmarksSearchType>) {
    this.searchType = event.selected;
    if (!this.checkMatches()) return;
    this.search.emit({
      text: this.searchText,
      type: this.searchType,
      availableOnly: this.availabilityFilter === this.SearchAvailabilityFilter.Available,
    });
  }

  public toggleAvailability(value: SearchAvailabilityFilter) {
    if (this.lockAvailabilityFilter) return;
    if (value == this.availabilityFilter) return;
    this.availabilityFilter = value;
    this.search.emit({
      text: this.searchText,
      type: this.searchType,
      availableOnly: this.availabilityFilter === this.SearchAvailabilityFilter.Available,
    });
  }

  private findMatchingIds(searchText: string, facet: FacetResult<ResponseFacet>) {
    const regEx = '/[^A-Z0-9]/ig';
    const text = searchText.toLowerCase().replace(regEx, '');
    const ids = facet.data.filter(value => value.label.toLowerCase().replace(regEx, '').includes(text)).map(value => value.id);
    return ids;
  }

  private checkMatches(): boolean {
    this.noMatchesFound = false;
    if (!this.searchText) return true;
    if ([BookmarksSearchType.Format, BookmarksSearchType.Location, BookmarksSearchType.Language].includes(this.searchType)) {
      const facet = this.facets[this.searchType as keyof BookmarksSearchFacets];
      const matches = this.findMatchingIds(this.searchText, facet);
      if (!matches.length) {
        this.noMatchesFound = true;
        return false;
      }
    }
    return true;
  }
}
