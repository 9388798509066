import { ViewType } from '../../entity/models/view';

export enum SiteTheme {
  STEAM_PUNK = 'STEAM_PUNK',
  COMIC = 'COMIC',
  VEGA_MODERN = 'VEGA_MODERN',
  VEGA_CLASSIC = 'VEGA_CLASSIC',
  DEFAULT = 'VEGA_CLASSIC',
  VEGA_CLASSIC_V2 = 'VEGA_CLASSIC_V2',
}

export interface ThemeSettings {
  customer_id: string | null;
  kiosk_id: string | null;
  id?: string;
  name?: SiteTheme | null;
  color?: string;
  images: string[];
  image_size?: ImagesSizeType;
}

export interface Announcement {
  customer_id: string;
  id: number;
  kiosk_id: string;
  message: string;
  status: string;
  type: string;
}

export interface PageSettings {
  id?: string;
  customer_id: string | null;
  kiosk_id: string | null;
  audience_theme_id: number;
  grid_display_option_id: number;
  use_global_settings: boolean;
  is_scoped_by_location?: boolean;
  is_scoped_by_collection?: boolean;
  collection_list?: string[];
  location_list?: string[];
  theme?: ThemeSettings;
  social_media_use_global_settings?: boolean;
  social_media_display?: SettingSocialMediaDisplay;
  announcements?: Announcement[];
  is_vegaprogram_enabled?: boolean;
  portal_fallback_showcase_ref?: string;
  portal_fallback_showcase_title?: string;
  portal_featured_showcase_ref?: string;
  portal_featured_showcase_title?: string;
}

export interface Settings extends PageSettings {
  search: {
    allowGrid: boolean;
    viewType: ViewType;
  };
  audience: {
    isKidsCatalog: boolean;
  };
}

export enum GridSettings {
  ALLOW_GRID = 2,
  DEFAULT_GRID = 3,
  DISABLE_GRID = 1,
}

export enum AudienceSettings {
  GENERAL = 1,
  KIDS_CATALOG = 2,
}

export enum ImagesSizeType {
  large = 'LARGE',
  medium = 'MEDIUM',
  small = 'SMALL',
}

export type SettingSocialMediaDisplay = {
  srp: boolean;
  individual_result_on_srp: boolean;
  frc: boolean;
  my_bookshelf: boolean;
};
