import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LocationWithName } from '../../../models/locations';

@Component({
  selector: 'app-pickup-location-select',
  templateUrl: './pickup-location-select.component.html',
  styleUrls: ['./pickup-location-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PickupLocationSelectComponent),
    multi: true
  }]
})
export class PickupLocationSelectComponent implements ControlValueAccessor {
  @Input() public pickupLocations: LocationWithName[];
  @Input() public selectedLocationCode: string;
  @Input() public dataAutomationId: string;
  @Output() codeChanging = new EventEmitter<string>();
  public disabled: boolean;

  private onChange: (value: string) => void;
  private onTouched: () => void;

  writeValue(value: string): void {
    this.selectedLocationCode = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onLocationChange(locationCode: string) {
    this.writeValue(locationCode);
    this.onChange(locationCode);
    this.onTouched();
    this.codeChanging.emit(locationCode);
  }
}
