import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-portal-root',
  templateUrl: './portal-root.component.html',
  styleUrls: ['./portal-root.component.scss'],
})
export class PortalRootComponent {
  public translations: any;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.translations = {
      itemLocation: this.translateService.instant('itemLocation'),
    };
  }
}
