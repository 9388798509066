import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TippyModule } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OverDriveModule } from 'app/core/over-drive/over-drive.module';
import { SharedModule } from 'common/shared.module';
import { HelpButtonComponent } from 'core/components/help-button/help-button.component';
import { LibraryInfoButtonComponent } from 'core/components/library-info-button/library-info-button.component';
import {
  LibraryInfoContentComponent,
  LibraryInfoScheduleComponent,
  LibraryInfoWidgetComponent,
} from 'core/components/library-info-widget';
import { ToggleBookmarksButtonComponent } from 'core/components/toggle-bookmarks-button/toggle-bookmarks-button.component';
import { MaintenanceModePageComponent } from 'core/containers/maintenance-mode-page/maintenance-mode-page.component';
import { NotFoundPageComponent } from 'core/containers/not-found-page/not-found-page.component';
import { ServerErrorPageComponent } from 'core/containers/server-error-page/server-error-page.component';
import { LibraryInfoEffects } from 'core/effects/library-info.effects';
import { QueryHelper } from 'core/utils/query-helper';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule, } from 'ngx-perfect-scrollbar';
import { UserModule } from 'user/user.module';
import { CustomerIntegrationModule } from '../customer-integration/customer-integration.module';
import { ElementsV2Module } from '../elements-v2/elements-v2.module';
import { HomePageComponent } from '../pages/home-page/home-page.component';
import { PortalRootComponent } from '../pages/portal-root/portal-root.component';
import { CookieNotificationComponent } from './components/cookie-notification/cookie-notification.component';
import { CountdownRedirectComponent } from './components/countdown-redirect/countdown-redirect.component';
import { GlobalHeaderContainerComponent } from './components/global-header-container/global-header-container.component';
import { GlobalHeaderComponent } from './components/global-header/global-header.component';
import { GlobalNavComponent } from './components/global-nav/global-nav.component';
import { GlobalSidebarComponent } from './components/global-sidebar/global-sidebar.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { AppComponent } from './containers/app/app.component';
import { ForbiddenPageComponent } from './containers/forbidden-page/forbidden-page.component';
import { RootPageComponent } from './containers/root-page/root-page.component';
import * as globalSidebar from './reducers/global-sidebar.reducer';
import * as libraryInfo from './reducers/library-info.reducer';
import * as fromHistory from 'search/reducers/history.reducer';
import * as fromSearchResults from 'search/reducers/root.reducer';
import { SearchResultHelper } from './utils/search-result-helper';
import { LogoutPageComponent } from './containers/logout-page/logout-page.component';

export const COMPONENTS = [
  AppComponent,
  HomePageComponent,
  PortalRootComponent,
  NotFoundPageComponent,
  MaintenanceModePageComponent,
  ForbiddenPageComponent,
  ServerErrorPageComponent,
  RootPageComponent,
  ToggleBookmarksButtonComponent,
  LanguageSwitcherComponent,
  LoginButtonComponent,
  GlobalHeaderComponent,
  GlobalHeaderContainerComponent,
  GlobalNavComponent,
  GlobalSidebarComponent,
  CookieNotificationComponent,
  LibraryInfoWidgetComponent,
  LibraryInfoContentComponent,
  LibraryInfoScheduleComponent,
  LibraryInfoButtonComponent,
  HelpButtonComponent,
  CountdownRedirectComponent,
  LogoutPageComponent,
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    UserModule,
    OverDriveModule,
    PerfectScrollbarModule,
    TippyModule,
    FeatureToggleModule,
    StoreModule.forFeature('libraryInfo', libraryInfo.reducer),
    StoreModule.forFeature(globalSidebar.featureKey, globalSidebar.reducer),
    StoreModule.forFeature('history', fromHistory.reducer),
    StoreModule.forFeature('search', fromSearchResults.reducer),
    EffectsModule.forFeature([LibraryInfoEffects]),
    FontAwesomeModule,
    NgbDropdownModule,
    A11yModule,
    ElementsV2Module,
    CustomerIntegrationModule,
  ],
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
  ],
  providers: [
    SearchResultHelper,
    QueryHelper,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class CoreModule {
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
