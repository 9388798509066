import { animate, query, style, transition, trigger, } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet, } from '@angular/router';
import { NgbConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { select, Store, } from '@ngrx/store';
import { BrowserTabTitleService } from 'core/services/browser-tab-title.service';

import { CookieNotificationService } from 'core/services/cookie.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ResetResourceFacetsAction } from 'search/actions/facet.actions';
import { setFirstNavigationId } from 'search/actions/history.actions';
import { getFirstPageId } from 'search/reducers/history.reducer';
import { State } from 'search/reducers/root.reducer';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { toggleOpenedLibraryInfoWidget } from 'user/actions/user-profile.actions';
import { getLibraryInfoWidgetState, getUserPanelState, } from 'user/reducers/user.reducer';
import { getGlobalSidebarVisibility } from 'core/reducers/global-sidebar.reducer';
import { environment } from '../../../../environments/environment';
import { FeatureToggleService } from 'app/services/feature-toggle.service';

@Component({
  styleUrls: ['./root-page.component.scss'],
  selector: 'app-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './root-page.component.html',
  animations: [
    trigger('animatePath', [
      transition('home => search', [
        query(':enter', [
          style({opacity: 0, position: 'absolute'}),
        ]),
        query(':leave', [
          animate('300ms ease-out', style({marginLeft: '288px'})),
        ]),
      ]),
      transition('search => home', [
        query(':enter', [
          style({marginLeft: '288px'}),
          animate('300ms ease-out', style({marginLeft: '0px'})),
        ]),
      ]),
    ]),
  ],
})
export class RootPageComponent implements OnInit, OnDestroy {
  @ViewChild('root', {static: true}) public root: ElementRef;
  @ViewChild('outlet', {static: true}) public outlet: RouterOutlet;
  @ViewChild('main', {static: true}) public main: ElementRef;
  public url: string;
  public seen: boolean;
  public isPortraitNotificationDisabled = false;
  public firstPageId: number;
  public animationState: string;
  public isPanelShown: boolean;
  public isLibraryInfoShown: boolean;
  public isBookshelfDisplayed = false;
  public isGlobalSidebarVisible: boolean;
  public fixedHeader: boolean;
  public baseClassName: string = '';
  private subscriptions: Subscription = new Subscription();
  public featureOneTrustBanner: boolean = false;
  public accountPortal: boolean = false;

  constructor(
    private router: Router,
    private cookieService: CookieNotificationService,
    private store: Store<State>,
    private titleService: BrowserTabTitleService,
    private route: ActivatedRoute,
    private chRef: ChangeDetectorRef,
    private ngbModalConfig: NgbModalConfig,
    private readonly ngbConfig: NgbConfig,
    private readonly configLoader: ConfigurationLoader,
    private readonly featureToggleService: FeatureToggleService,
  ) {
  }

  public ngOnInit() {
    this.url = this.router.url;
    this.titleService.setTabTitle();
    this.fixedHeader = this.configLoader.headerConfig?.fixedHeader;

    if (environment.disableAnimation) {
      this.ngbConfig.animation = false;
    }

    this.ngbModalConfig.centered = true;
    this.ngbModalConfig.windowClass = 'inspire-custom-modal';
    this.addStoreSubscriptions();
    this.addRouterEventsSubscriptions();

    this.accountPortal = this.featureToggleService.getToggles()['VE-5143_2025-12-31_Account-Portal'];

    this.featureOneTrustBanner = this.featureToggleService.getToggles()['VE-3951_2024-04-30_discover-one-trust-banner'];
    if (!this.featureOneTrustBanner) {
      this.subscriptions.add(
        this.cookieService.seen$.subscribe((data) => {
          this.seen = data;
        }
      ));
    }
  }

  public addRouterEventsSubscriptions() {
    this.subscriptions.add(this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.accountPortal = this.featureToggleService.getToggles()['VE-5143_2025-12-31_Account-Portal'];

        this.setDefaultTabTitle(event.url);

        this.url = event.url;

        if (!/^\/search\/card(\W|)/.test(event.url)) {
          this.animationState = this.outlet.activatedRouteData.page;
        }
      }));

    this.subscriptions.add(this.router.events.pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          if (this.firstPageId === event.restoredState?.navigationId) {
            this.store.dispatch(setFirstNavigationId({navId: event.id}));
          }
        }
        if ((event.url === this.url) && (event.id - this.firstPageId === 1)) {
          this.store.dispatch(setFirstNavigationId({navId: event.id}));
        }
      }));

    this.subscriptions.add(this.route.queryParams.subscribe((params: any) => {
      if (params.libraryInfoWidget === 'opened') {
        this.store.dispatch(toggleOpenedLibraryInfoWidget({isOpened: true}));
      }
    }));
  }

  public addStoreSubscriptions() {
    this.subscriptions.add(this.store.pipe(select(getFirstPageId)).subscribe((id) => {
      this.firstPageId = id;
    }));
    this.subscriptions.add(this.store.pipe(select(getUserPanelState)).subscribe((isOpened) => {
      this.isPanelShown = isOpened;
      this.chRef.markForCheck();
    }));
    this.subscriptions.add(this.store.pipe(select(getLibraryInfoWidgetState)).subscribe((isOpened) => {
      this.isLibraryInfoShown = isOpened;
    }));
    this.subscriptions.add(this.store.pipe(select(getGlobalSidebarVisibility)).subscribe((isVisible) => {
      this.isGlobalSidebarVisible = isVisible;
    }));
  }

  public setDefaultTabTitle(url: string) {
    if (['/', '/search'].includes(url)) {
      this.titleService.setTabTitle();
    }
  }

  public resetResourceFacets() {
    this.store.dispatch(new ResetResourceFacetsAction());
  }

  public skipToElement(element: string) {
    (this as any)[element].nativeElement.focus();
  }

  public onPortraitNotificationClick() {
    this.isPortraitNotificationDisabled = true;
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
