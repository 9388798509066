import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { loadMoreListItems, loginFromBookmarksTab, openList } from '../../actions/list.actions';
import { BookmarksSearchFacets, ListItem, ListWithItemsCount } from '../../models/list';
import { ListService } from '../../services/list.service';
import { BookmarkSearch } from '../searchable-bookmarks-search-bar/searchable-bookmarks-search-bar.component';
import { getOpenedList, getSearchFacets, isLastPageLoaded } from 'app/list/reducers/list.reducer';

@Component({
  selector: 'app-searchable-bookmarks-lists',
  templateUrl: './searchable-bookmarks-lists.component.html',
  styleUrls: ['./searchable-bookmarks-lists.component.scss']
})
export class SearchableBookmarksListsComponent {
  @Input() public lists: ListWithItemsCount[];
  @Input() public authorized: boolean;
  @Input() public isStaffAuthorized: boolean;
  @Output() public openList = new EventEmitter();
  @Output() public openAvailableList = new EventEmitter();
  public bookmarkIcon = faBookmark;
  public preview: ListItem[];

  // view data - search
  public listsSearch: ListWithItemsCount[] = [];
  public isSearch = false;
  public loading: boolean = false;
  public facets: BookmarksSearchFacets;
  public searchPreview: ListItem[] = [];
  private latestSearch: BookmarkSearch;
  private listsToSearch: string[] = [];

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly listService: ListService,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions.add(this.store.select(getOpenedList).subscribe(openedList => {
      if (!this.isSearch || !openedList) {
        return;
      }
      this.searchList(openedList, this.latestSearch);
    }));
    this.subscriptions.add(this.store.select(getSearchFacets).subscribe(facets => this.facets = facets));
  }

  ngOnInit() {
    this.preview = this.listService.generateSearchPreview(this.lists);
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public logIn(event: Event): void {
    event.stopPropagation();
    this.store.dispatch(loginFromBookmarksTab());
  }

  public trackById(index: number, list: ListWithItemsCount): string {
    return list.id;
  }

  public onSearch(search: BookmarkSearch) {
    if (search.text || search.availableOnly) {
      this.isSearch = true;
    } else {
      this.isSearch = false;
      this.preview = this.listService.generateSearchPreview(this.lists);
      this.loading = false;
      return;
    }
    this.latestSearch = search;
    this.loading = true;
    this.listsSearch = [];
    this.listsToSearch = this.lists.map(({id}) => id);
    this.searchNext();
  }

  public searchList(openedList: ListWithItemsCount, search: BookmarkSearch) {
    if (this.listsSearch.find(l => l.id === openedList.id)) {
      return;
    }
    if (!openedList.itemsLoadingState.loading && !isLastPageLoaded(openedList)) {
      this.store.dispatch(loadMoreListItems({id: openedList.id}));
      return;
    }
    this.listService.findMatches(openedList, search, this.facets);
    const openedListSearch = {...openedList, items: openedList.items.filter(i => i.matched)};
    openedListSearch.itemsCount = openedListSearch.items.length;
    this.listsSearch.push(openedListSearch);
    this.searchNext();
  }

  public searchNext() {
    if (this.listsToSearch.length) {
      const list = this.listsToSearch.shift();
      this.store.dispatch(openList({listId: list}));
    } else {
      this.searchPreview = this.listService.generateSearchPreview(this.listsSearch);
      this.loading = false;
    }
  }
}
