import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbPopoverModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { TippyModule } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GetItAfterLoginEffects } from 'app/entity/effects/get-it-after-login.effects';
import { SharedModule } from 'common/shared.module';
import { OverDriveEffects } from 'core/over-drive/effects/over-drive.effects';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BookshelfModule } from 'user/components/bookshelf/bookshelf.module';
import { BulkHoldTableComponent } from 'user/components/bulk-hold/bulk-hold-table/bulk-hold-table.component';
import { PickupLocationSelectComponent } from 'user/components/pickup-location-select/pickup-location-select.component';
import {
  ChangeHoldLocationDialogComponent
} from 'user/components/change-hold-location-dialog/change-hold-location-dialog.component';
import { ItemVolumeListSortComponent } from 'user/components/item-volume-list-sort/item-volume-list-sort.component';
import { ItemVolumeListComponent } from 'user/components/item-volume-list/item-volume-list.component';
import { NotificationsModule } from 'user/components/notifications/notifications.module';
import { SessionExpiredDialogComponent } from 'user/components/session-expired-dialog/session-expired-dialog.component';
import { BulkHoldUnauthorizedFlowEffects } from 'user/effects/bulk-hold-unauthorized-flow.effects';
import { BulkHoldEffects } from 'user/effects/bulk-hold.effects';
import { GetItEffects } from 'user/effects/get-it.effects';
import { ActiveInterceptor } from 'user/services/active.interceptor';
import { GetItService } from 'user/services/get-it.service';
import { AddAriaDirective } from './components/get-it-dialog/directives/add-aria.directive';
import { GetItDialogComponent } from './components/get-it-dialog/get-it-dialog.component';
import { GetItErrorDialogComponent } from './components/get-it-error-dialog/get-it-error-dialog.component';
import { GetItSuccessDialogComponent } from './components/get-it-success-dialog/get-it-success-dialog.component';
import { InspireLoginFormComponent } from './components/inspire-login-form/inspire-login-form.component';
import { InspireMyAccountQuickViewComponent } from './components/inspire-my-account-quick-view/inspire-my-account-quick-view.component';
import { InspireSignInComponent } from './components/inspire-sign-in/inspire-sign-in.component';
import { InspireUserPanelComponent } from './components/inspire-user-panel/inspire-user-panel.component';
import { VolumeListComponent } from './components/volume-list/volume-list.component';
import { PatronUserEffects } from './effects/patron-user.effects';
import { UserProfileEffects } from './effects/user-profile.effects';
import * as fromUser from './reducers/user.reducer';
import { PatronUserService } from './services/patron-user.service';
import { UserProfileService } from './services/user-profile.service';
import { BorrowByMailUserAddressComponent } from './components/get-it-dialog/borrow-by-mail-user-address/borrow-by-mail-user-address.component';
import { ChangePasscodeDialogComponent } from 'user/components/account/profile/change-passcode-dialog/change-passcode-dialog.component';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { EntityEffects } from 'app/entity/effects/entity.effects';
import * as entity from '../entity/reducers/entity.reducer';
import { bulkRenewFeatureKey } from 'user/reducers/bulk-renew.reducer';
import * as fromBulkRenew from 'user/reducers/bulk-renew.reducer';
import * as bulkHold from './reducers/bulk-hold.reducer';
import { BulkHoldModalComponent } from './components/bulk-hold/bulk-hold-modal/bulk-hold-modal.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HoldSubmitStateComponent } from './components/bulk-hold/hold-submit-state/hold-submit-state.component';
import { BulkHoldCountersComponent } from './components/bulk-hold/bulk-hold-counters/bulk-hold-counters.component';
import { IssueListComponent } from './components/issue-list/issue-list.component';
import {
  IneligibleForBulkHoldBannerComponent
} from './components/bulk-hold/ineligible-for-bulk-hold-banner/ineligible-for-bulk-hold-banner.component';
import {
  IneligibleForBulkHoldModalComponent
} from './components/bulk-hold/ineligible-for-bulk-hold-modal/ineligible-for-bulk-hold-modal.component';
import { BulkHoldItemOverviewComponent } from './components/bulk-hold/bulk-hold-item-overview/bulk-hold-item-overview.component';
import { HoldNeededDateFormComponent } from './components/hold-needed-date-form/hold-needed-date-form.component';
import { CustomerIntegrationModule } from 'app/customer-integration/customer-integration.module';
import { PickupAreaSelectComponent } from './components/pickup-area-select/pickup-area-select.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    StoreModule.forFeature('user', fromUser.reducer),
    StoreModule.forFeature(entity.entityFeatureKey, entity.reducer),
    StoreModule.forFeature(bulkRenewFeatureKey, fromBulkRenew.reducer),
    StoreModule.forFeature(bulkHold.featureKey, bulkHold.reducer),
    EffectsModule.forFeature([
      UserProfileEffects,
      GetItAfterLoginEffects,
      GetItEffects,
      OverDriveEffects,
      PatronUserEffects,
      EntityEffects,
      BulkHoldEffects,
      BulkHoldUnauthorizedFlowEffects,
    ]),
    ReactiveFormsModule,
    BookshelfModule,
    NotificationsModule,
    FormsModule,
    MatDatepickerModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    TippyModule,
    MatSelectModule,
    PerfectScrollbarModule,
    FontAwesomeModule,
    FeatureToggleModule,
    InfiniteScrollModule,
    CustomerIntegrationModule,
  ],
  declarations: [
    GetItDialogComponent,
    GetItSuccessDialogComponent,
    GetItErrorDialogComponent,
    ChangeHoldLocationDialogComponent,
    SessionExpiredDialogComponent,
    InspireUserPanelComponent,
    InspireSignInComponent,
    InspireMyAccountQuickViewComponent,
    InspireLoginFormComponent,
    AddAriaDirective,
    VolumeListComponent,
    ItemVolumeListComponent,
    ItemVolumeListSortComponent,
    BorrowByMailUserAddressComponent,
    ChangePasscodeDialogComponent,
    BulkHoldModalComponent,
    BulkHoldTableComponent,
    PickupLocationSelectComponent,
    HoldSubmitStateComponent,
    BulkHoldCountersComponent,
    IssueListComponent,
    IneligibleForBulkHoldBannerComponent,
    IneligibleForBulkHoldModalComponent,
    BulkHoldItemOverviewComponent,
    HoldNeededDateFormComponent,
    PickupAreaSelectComponent,
  ],
  providers: [
    UserProfileService,
    GetItService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActiveInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    PatronUserService
  ],
  exports: [
    BookshelfModule,
    NotificationsModule,
    InspireUserPanelComponent,
  ],
})
export class UserModule {
}
