import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { faFacebook, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faShareNodes } from '@fortawesome/pro-light-svg-icons';
import { WindowRefService } from 'app/services/window-ref.service';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import {
  ShareOnSocialMediaService,
  SocialMediaType
} from '../../../services/share-on-social-media.service';
import { DiscoverV2BffService } from '../../../elements-v2/service/discover-v2-bff.service';
import { ShareOptions } from '../../../models/MetaData';
import { truncateString } from 'core/utils/string-helper';

@Component({
  selector: 'app-share-icons-button',
  templateUrl: './share-icons-button.component.html',
  styleUrls: ['./share-icons-button.component.scss']
})
export class ShareIconsButtonComponent implements OnInit {
  @Input() public smallButton?: boolean = false;
  @Input() public bigButton?: boolean = false;
  @Input() public isSearchPage?: boolean = false;
  @Input() public disabled: boolean;
  @Input() shareData: ShareOptions;
  @ViewChild('shareIconsToggleButton') public shareIconsToggleButton: ElementRef;
  public shareUrl: string;
  public faFacebook = faFacebook;
  public faXTwitter = faXTwitter;
  public faShareNodes = faShareNodes;
  public isSocialMediaShareEnabled: boolean;
  protected readonly SocialMediaType = SocialMediaType;

  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly shareOnSocialMediaService: ShareOnSocialMediaService,
    private readonly discoverV2BffService: DiscoverV2BffService,
  ) {
  }

  public ngOnInit() {
    this.isSocialMediaShareEnabled = this.featureToggleService.getToggles()['DIS-30364_2024-06-30_Social-Media-Share'];
  }

  private createTwitterShareUrl(url: string) {
    const shareTwitterUrl = this.shareOnSocialMediaService.socialMediaShareLinkHandler(SocialMediaType.TWITTER, url);
    this.windowRefService.nativeWindow().open(shareTwitterUrl, '_blank');
  }

  private createFacebookShareUrl(url: string) {
    const shareFaceBookUrl = this.shareOnSocialMediaService.socialMediaShareLinkHandler(SocialMediaType.FACEBOOK, url);
    this.windowRefService.nativeWindow().open(shareFaceBookUrl, '_blank');
  }

  public shareOnMedia(type: SocialMediaType) {
    if (this.isSearchPage) {
      const url = this.windowRefService.nativeWindow().location.href;
      const newUrl = new URL(url);
      const query = newUrl.searchParams.get('query');
      const id = Date.now();
      const body: ShareOptions = {
        id,
        title: truncateString(query, 50),
        description: '',
        image: this.shareData.image,
        url: encodeURIComponent(url)
      };

      this.discoverV2BffService.sharePage(body).subscribe(
        (data) => {
          if (type === SocialMediaType.FACEBOOK) {
            this.createFacebookShareUrl(data.file.location);
          } else {
            this.createTwitterShareUrl(data.file.location);
          }
        });
    } else {
      this.discoverV2BffService.sharePage(this.shareData).subscribe(
        (data: any) => {
           if (type === SocialMediaType.FACEBOOK) {
            this.createFacebookShareUrl(data.file.location);
          } else {
            this.createTwitterShareUrl(data.file.location);
          }
        });
    }
  }
}
