import { Action, createAction, props } from '@ngrx/store';
import { SearchRequestBody } from 'search/models/search-results';
import { Entity, FormatGroup, PersonalList } from '../../entity/models/entity';
import { BookmarkButtonState } from '../models/bookmark-button-state';
import {
  BookmarkedStatus,
  CreateListError,
  DeleteListWithShowcaseError,
  GetListItemsError,
  ListItem,
  ListItemEntity,
  ListSort,
  ListType,
  ListUpdate,
  ListWithItemsCount,
  LoadedList,
  LoadListItemsPayload,
  PatchListItemsError,
  BookmarksSearchFacets,
  UpdateListError,
} from '../models/list';
import { ListDto, ListPagination } from '../models/list.dto';
import { SelectListTrigger } from '../models/select-list';

export const loadListsComplete = createAction(
  '[List] Load lists complete',
  props<{ listsLoaded: LoadedList[] }>(),
);

export const createList = createAction(
  '[List] Create list',
  props<{ name: string }>(),
);

export const openEmailModal = createAction(
  '[List] Open email modal',
  props<{ sharedList: ListWithItemsCount }>(),
);


export const getListComplete = createAction(
  '[List] Get showcase saved list complete',
  props<{ id: string }>(),
);

export const focusShareButton = createAction(
  '[List] Focus share button',
  props<{ savedSearchId: string }>(),
);

export const createListComplete = createAction(
  '[List] Create list complete',
  props<{ list: { id: string, name: string, type: ListType, sort: ListSort } }>(),
);

export const createListFailure = createAction(
  '[List] Create list failure',
  props<{ error: CreateListError }>(),
);

export const resetListCreatedState = createAction(
  '[List] Reset list created state',
);

export const updateList = createAction(
  '[List] Update list',
  props<{ request: ListUpdate }>(),
);

export const updateListComplete = createAction(
  '[List] Update list complete',
  props<{ listUpdate: ListUpdate }>(),
);

export const updateListFailure = createAction(
  '[List] Update list failure',
  props<{ id: string, error: UpdateListError }>(),
);

export const resetListUpdatedState = createAction(
  '[List] Reset list updated state',
);

export const deleteList = createAction(
  '[List] Delete list',
  props<{ id: string, showcaseRemoveResult?: 'success' | 'error' }>(),
);

export const deleteListComplete = createAction(
  '[List] Delete list complete',
  props<{ id: string }>(),
);

export const deleteListFailure = createAction(
  '[List] Delete list failure',
  props<{ id: string, error: DeleteListWithShowcaseError }>(),
);

export const resetListDeleteState = createAction(
  '[List] Reset list delete state',
);

export const changeListSort = createAction(
  '[List] Change sort',
  props<{ id: string, sort: ListSort }>(),
);

export const loadMoreListItems = createAction('[List] Load more list items', props<{ id: string }>());

export const loadListItems = createAction(
  '[List] Load list items',
  props<LoadListItemsPayload>(),
);

export const loadListItemsComplete = createAction(
  '[List] Load list items sort complete',
  props<{ id: string, pagination: ListPagination }>(),
);

export const addLoadedItems = createAction(
  '[List] Add loaded items',
  props<{ id: string, entities: ListItemEntity[] }>(),
);

export const loadListItemsFailure = createAction(
  '[List] Load list items sort failure',
  props<{ id: string, error: GetListItemsError }>(),
);

export const openList = createAction(
  '[List] Open list',
  props<{ listId: string }>(),
);

export const listClosed = createAction(
  '[List] List closed',
);

export const addBookmarkViaButton = createAction(
  '[List] Add bookmark via button',
  props<{ entity: ListItemEntity }>(),
);

export const moveBookmarks = createAction(
  '[List] Move bookmarks',
  props<{ entities: ListItemEntity[], toListIds?: string[], fromListIds?: string[], actionsOnSuccess?: Action[], actionsOnFail?: Action[] }>(),
);

export const moveBookmarksFailure = createAction(
  '[List] Move bookmarks failure',
  props<{ entities: ListItemEntity[], toListIds?: string[], fromListIds?: string[], error: PatchListItemsError }>(),
);

export const addBookmarksComplete = createAction(
  '[List] Add bookmarks complete',
  props<{ entities: ListItemEntity[], listIds: string[] }>(),
);

export const removeBookmarksComplete = createAction(
  '[List] Remove bookmarks complete',
  props<{ entities: ListItemEntity[], listIds: string[] }>(),
);

export const moveBookmarksViaSelectList = createAction(
  '[List] Move bookmarks via select list',
  props<{ entities: ListItemEntity[], toListIds?: string[], fromListIds?: string[], trigger?: SelectListTrigger }>(),
);

export const moveBookmarksViaSelectListDone = createAction(
  '[List] Move bookmarks via select list done',
  props<{ entities: ListItemEntity[], toListIds?: string[], fromListIds?: string[] }>(),
);

export const reloadLists = createAction(
  '[List] Reload lists',
  props<{ listIds: string[] }>(),
);

export const moveBookmarksViaSelectListFailure = createAction(
  '[List] Move bookmarks via select list failure',
);

export const resetSelectListState = createAction(
  '[List] Reset select list state',
);

export const removeBookmarkViaButton = createAction(
  '[List] Remove bookmark via button',
  props<{ entity: ListItemEntity }>(),
);

export const removeBookmarkViaButtonSingleList = createAction(
  '[List] Remove bookmark via button single list',
  props<{ entity: ListItemEntity, listId: string }>(),
);

export const removeSelectedFromList = createAction(
  '[List] Remove selected from list',
  props<{ listId: string }>(),
);

export const toggleItemSelection = createAction(
  '[List] Toggle item selection',
  props<{ listId: string, itemId: string }>(),
);

export const setAllItemsSelection = createAction(
  '[List] Set all items selection',
  props<{ listId: string, selected: boolean }>(),
);

export const clear = createAction(
  '[List] Clear',
  props<{ id: string }>(),
);

export const setBookmarkButtonState = createAction(
  '[List] Set bookmark button state',
  props<{
    entityId: string,
    personalLists?: PersonalList[],
    bookmarkButtonState: BookmarkButtonState,
    justAddedListId?: string,
    setOnLogin?: true
  }>(),
);

export const setBookmarkButtonsStates = createAction(
  '[List] Set bookmark buttons states',
  props<{ entityIds: string[], bookmarkButtonState: BookmarkButtonState, setOnLogin?: true }>(),
);

export const resetEntityBookmarkedState = createAction(
  '[List] Reset entity bookmarked state',
  props<{ entityId: string }>(),
);

export const resetEntitiesBookmarkedState = createAction(
  '[List] Reset entities bookmarked state',
  props<{ entityIds: string[] }>(),
);

export const setEntitiesBookmarkedState = createAction(
  '[List] Set entities bookmarked state',
  props<{ bookmarkedStatuses: BookmarkedStatus[] }>(),
);

export const loginFromBookmarkButton = createAction(
  '[List] Login from bookmark button',
  props<{ entityId: string }>(),
);

export const loginFromBookmarksTab = createAction(
  '[List] Login from bookmarks tab',
);

export const notifyListsLoaded = createAction(
  '[List] Notify lists loaded',
  props<{ listDtos?: ListDto[] }>(),
);

export const notifyNewEntitiesOnPage = createAction(
  '[List] Notify new entities on page',
  props<{ entities: (Entity | FormatGroup)[] }>(),
);

export const notifyWillSaveBookmarksOnLogin = createAction(
  '[List] Notify will save bookmarks on login',
  props<{ willSaveBookmarks: boolean }>(),
);

export const saveBookmarksOnRedirect = createAction(
  '[List] Save bookmarks on redirect',
  props<{ entityId: string }>(),
);

export const shouldSaveBookmarksOnPageLoad = createAction(
  '[List] Should save bookmarks on page load',
  props<{ entityIdTriggeredLogin?: string }>(),
);

export const focusBookmarkButton = createAction(
  '[List] Focus bookmark button',
  props<{ entityId: string }>(),
);

export const stopFocusBookmarkButton = createAction(
  '[List] Stop focus bookmark button',
);

export const focusAvailableListCard = createAction(
  '[List] Focus available card',
);

export const stopFocusAvailableListCard = createAction(
  '[List] Stop focus card',
);

export const openBookmarksSaveResultModal = createAction(
  '[List] Open bookmarks save result modal',
  props<{ success: boolean }>(),
);

export const openSaveMultipleBookmarksModal = createAction(
  '[List] Open save multiple bookmarks modal',
  props<{ entities: ListItemEntity[] }>(),
);

export const setDefaultListId = createAction(
  '[List] Set default list id',
  props<{ id: string }>(),
);

export const setListShowcaseRef = createAction(
  '[List] Set list showcase ref',
  props<{ listId: string, showcaseRef: string }>(),
);

export const setListSort = createAction(
  '[List] Set list sort',
  props<{ listId: string, sort: ListSort }>(),
);

export const unsetListShowcaseRef = createAction(
  '[List] Unset list showcase ref',
  props<{ id: string }>(),
);

export const copyUrl = createAction(
  '[List] Copy url',
  props<{ id: string }>(),
);

export const shareOnFacebook = createAction(
  '[List] Share on Facebook',
  props<{ id: string, title: string, image: string }>()
);

export const shareOnTwitter = createAction(
  '[List] Share on Twitter',
  props<{ id: string, title: string, image: string }>()
);

export const redirectToSocialMediaSuccess = createAction(
  '[List] Share on Twitter and Facebook Success',
);

export const writeUrl = createAction(
  '[List] Write URL',
  props<{ id: string, url: string }>(),
);

export const setLastCopied = createAction(
  '[List] Set last copied',
  props<{ id: string }>(),
);

export const setSearchFacets = createAction(
  '[List] Set search facets',
);

export const setSearchFacetsComplete = createAction(
  '[List] Set search facets complete',
  props<{ searchFacets: BookmarksSearchFacets }>(),
);
