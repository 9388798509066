import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faConveyorBeltAlt, faCopy, faEdit, faEnvelope, faTrash } from '@fortawesome/pro-light-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { openEmailModal, shareOnFacebook, shareOnTwitter } from 'app/list/actions/list.actions';
import { getFocusShareButton } from 'app/list/reducers/list.reducer';
import { stopFocusShareButton } from 'app/saved-search/actions/saved-search.actions';
import { BookshelfCardActionStateTypeEnum } from 'common/components/bookshelf-card-action-state/bookshelf-card-action-state.component';
import { BookshelfCardType } from 'common/components/bookshelf-card-status/bookshelf-card-status.component';
import { BookshelfCardActionPayload, BookshelfCardShareActionType, ListCardActionType } from 'common/models/bookshelf-card';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { areEqualDeeply } from 'shared/utils/are-equal-deeply';
import { openShowcasesTab } from 'user/actions/user-profile.actions';
import { UserPermission } from 'user/models/user';
import { doesUserHavePermission } from 'user/reducers/user.reducer';
import { hideForm, openShowcase, showForm } from '../../../custom-showcase/actions/custom-showcase.actions';
import { CustomShowcase, CustomShowcaseCreatedFromType } from '../../../custom-showcase/models/custom-showcase';
import { getMyShowcase } from '../../../custom-showcase/reducers/custom-showcase.reducer';
import { GetState } from '../../../saved-search/reducers/saved-search.reducer';
import { CopyToClipboardService } from '../../../services/copy-to-clipboard.service';
import { copyUrl, setLastCopied } from '../../actions/list.actions';
import { ListType, ListWithItemsCount } from '../../models/list';
import { copyState } from '../../reducers/copy-list-url.reducer';
import { DeleteListModalComponent } from '../delete-list-modal/delete-list-modal.component';
import { EditListNameModalComponent } from '../edit-list-name-modal/edit-list-name-modal.component';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { SitePermissions } from 'app/permissions/models/permissions';
import { faXTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { DiscoverV2BffService } from 'app/elements-v2/service/discover-v2-bff.service';


enum DisplayState {
  NONE,
  SHARE_COPIED,
}

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
})
export class ListCardComponent implements OnInit, OnDestroy {
  @Input() public list: ListWithItemsCount;
  @Output() public readonly onListOpened = new EventEmitter();

  public relatedShowcase?: CustomShowcase;
  public ariaTitleId: string;
  public sitePermissions = SitePermissions;
  public isAuthPatronFlagEnabled: boolean;
  public bookshelfShowShareIcon: boolean;


  public readonly focus$: Observable<void>;
  public readonly focusActionsMenuToggleButton$: Observable<void>;
  public readonly hasViewShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_VIEW));
  public readonly hasCreateShowcasePermission$ = this.store.select(doesUserHavePermission(UserPermission.SHOWCASES_CREATE));
  public readonly cardActionTypeEnum = ListCardActionType;
  public readonly listType = ListType;
  public readonly showcaseIcon = faConveyorBeltAlt;
  public readonly editIcon = faEdit;
  public readonly trashIcon = faTrash;
  public readonly copyIcon = faCopy;
  public readonly emailIcon = faEnvelope;
  public readonly faXTwitter = faXTwitter;
  public readonly faFacebook = faFacebook;
  public readonly bookshelfCardType = BookshelfCardType;
  public readonly cardShareActionTypeEnum = BookshelfCardShareActionType;
  public readonly focusShareMenuToggleButton$: Observable<void>;
  public readonly displayStateEnum = DisplayState;
  public readonly cardActionStateTypeEnum = BookshelfCardActionStateTypeEnum;
  public displayState: DisplayState = DisplayState.NONE;

  private readonly subscriptions = new Subscription();
  private readonly focusSubject = new Subject<void>();
  private readonly focusActionsMenuToggleButtonSubject = new Subject<void>();
  private readonly focusShareMenuToggleButtonSubject = new Subject<void>();

  constructor(
    private readonly modal: NgbModal,
    private readonly store: Store,
    private readonly copyService: CopyToClipboardService,
    private readonly cdr: ChangeDetectorRef,
    private readonly featureToggleService: FeatureToggleService,
    private discoverV2BffService: DiscoverV2BffService
  ) {
    this.focusActionsMenuToggleButton$ = this.focusActionsMenuToggleButtonSubject.asObservable();
    this.focus$ = this.focusSubject.asObservable();
    this.focusShareMenuToggleButton$ = this.focusShareMenuToggleButtonSubject.asObservable();
  }

  public ngOnInit(): void {
    this.ariaTitleId = `list-card-${this.list.id}`;
    this.isAuthPatronFlagEnabled = this.featureToggleService.getToggles()['DIS-30793_2024-04-27_auth_patron'];
    this.subscriptions.add(this.discoverV2BffService.$page.subscribe((page) => {
      if (page) {
        this.bookshelfShowShareIcon = page.settings?.social_media_display?.my_bookshelf &&
          this.featureToggleService.getToggles()['DIS-30364_2024-06-30_Social-Media-Share'];
      }
    }));

    if (this.list.showcaseRef) {
      this.subscriptions.add(
        this.store.select(getMyShowcase, {id: this.list.showcaseRef})
        .subscribe((showcase) => {
          this.relatedShowcase = showcase;
        }),
      );
    }
    this.subscriptions.add(
      this.store.select(getFocusShareButton).subscribe((focusShareButton) => {
        if (focusShareButton && focusShareButton === this.list.id) {
          this.store.dispatch(stopFocusShareButton());
          this.focusShareMenuToggleButtonSubject.next();
        }
      }),
    );

    this.subscriptions.add(
      this.store.select(copyState, {id: this.list.id})
      .pipe(distinctUntilChanged(areEqualDeeply))
      .subscribe((getState) => {
        this.setCopyState(getState);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.focusSubject.complete();
    this.focusActionsMenuToggleButtonSubject.complete();
    this.focusShareMenuToggleButtonSubject.complete();
    this.subscriptions.unsubscribe();
  }

  public triggerCardAction(actionPayload: BookshelfCardActionPayload<ListCardActionType>) {
    switch (actionPayload.actionType) {
      case ListCardActionType.edit: {
        this.onEditAction();
        break;
      }
      case ListCardActionType.delete: {
        this.onDeleteAction();
        break;
      }
      case ListCardActionType.createShowcase: {
        const createdFrom = {id: this.list.id, name: this.list.name, type: CustomShowcaseCreatedFromType.list};
        this.store.dispatch(showForm({showcase: {createdFrom}}));
        break;
      }
      case ListCardActionType.viewShowcase: {
        this.viewShowcase();
        break;
      }
    }
  }

  public triggerCardShareAction(actionPayload: BookshelfCardActionPayload<BookshelfCardShareActionType, undefined>) {
    const data = {
      id: this.list.id,
      title: this.list.name,
      image: this.list.items?.[0]?.entity?.coverConfig?.coverUrl.large,
    };

    switch (actionPayload.actionType) {
      case BookshelfCardShareActionType.email: {
        this.store.dispatch(openEmailModal({sharedList: this.list}));
        break;
      }
      case BookshelfCardShareActionType.copyLink: {
        this.store.dispatch(copyUrl({id: this.list.id}));
        break;
      }
      case BookshelfCardShareActionType.facebook: {
        this.store.dispatch(shareOnFacebook(data));
        break;
      }
      case BookshelfCardShareActionType.twitter: {
        this.store.dispatch(shareOnTwitter(data));
        break;
      }
    }
  }

  public onEditAction(): void {
    const modalRef = this.modal.open(
      EditListNameModalComponent,
      {
        windowClass: 'inspire-custom-modal',
        ariaLabelledBy: 'edit-list-name-modal-title',
      },
    );
    modalRef.componentInstance.list = this.list;
    modalRef.result.finally(() => {
      this.focusActionsMenuToggleButtonSubject.next();
    });
  }

  public onDeleteAction(): void {
    const modalRef = this.modal.open(
      DeleteListModalComponent,
      {
        windowClass: 'inspire-custom-modal',
        ariaLabelledBy: 'delete-list-modal-title',
      },
    );
    modalRef.componentInstance.list = this.list;
    modalRef.result.catch(() => {
      this.focusActionsMenuToggleButtonSubject.next();
    });
  }

  public viewShowcase() {
    this.store.dispatch(hideForm());
    this.store.dispatch(openShowcasesTab());
    this.store.dispatch(openShowcase({id: this.list.showcaseRef}));
  }

  public focus() {
    this.focusSubject.next();
  }

  public clearStatus(): void {
    this.displayState = DisplayState.NONE;
  }

  private setCopyState(getState: GetState): void {
    this.displayState = DisplayState.NONE;

    if (getState) {
      if (getState.url) {
        this.copyToClipboard(getState.url);
        this.displayState = DisplayState.SHARE_COPIED;
        this.focusShareMenuToggleButtonSubject.next();
        this.store.dispatch(setLastCopied({id: this.list.id}));
      } else if (getState.lastCopied) {
        this.displayState = DisplayState.SHARE_COPIED;
      }
      this.cdr.detectChanges();
    }
  }

  private copyToClipboard(text: string): void {
    this.copyService.copyToClipboard(text);
  }
}
