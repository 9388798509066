import { Router } from '@angular/router';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from 'search/reducers/root.reducer';
import { openBookshelfAccountTab } from 'user/actions/user-profile.actions';
import { AccountTabs } from 'user/models/bookshelf';
import { StaffUser, User } from 'user/models/user';
import { CustomerFeature, CustomerIntegrationLinks } from '../../../customer-integration/customer-integration';
import { CustomerFeatureToggleService } from '../../../customer-integration/services/customer-feature-toggle.service';
import { WindowRefService } from '../../../services/window-ref.service';
import { faCatSpace, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FeatureToggleService } from '../../../services/feature-toggle.service';

export enum PatronAccountClick {
  PatronName = 'patron name',
  CheckoutsCount = 'Checkouts count',
  HoldsCount = 'Holds count',
  FinesFeesAmount = 'Fines/Fees Amount',
}

@Component({
  selector: 'app-inspire-my-account-quick-view',
  templateUrl: './inspire-my-account-quick-view.component.html',
  styleUrls: ['./inspire-my-account-quick-view.component.scss'],
})
export class InspireMyAccountQuickViewComponent {
  @Input() public user$: Observable<User>;
  @Input() public userNicknameOrName$: Observable<string>;
  @Input() public isStaffAuthorized: boolean;
  @Input() public staffUser$: Observable<StaffUser>;
  @Input() public staffUserName$: Observable<string>;
  @Input() public checkoutsTotal$: Observable<number>;
  @Input() public holdsTotal$: Observable<number>;
  @Input() public moneyOwed$: Observable<number>;
  @Input() public guestLibraryEmail: string;
  @Input() public customLinks?: CustomerIntegrationLinks;
  @Output() public onSignOut = new EventEmitter();
  @Output() public onClose = new EventEmitter();

  public readonly PatronName = PatronAccountClick.PatronName;
  public readonly CheckoutsCount = PatronAccountClick.CheckoutsCount;
  public readonly HoldsCount = PatronAccountClick.HoldsCount;
  public readonly FinesFeesAmount = PatronAccountClick.FinesFeesAmount;

  public readonly CustomerFeature = CustomerFeature;
  public readonly userIcon = faUser;
  public readonly staffUserIcon = faCatSpace;
  public isUserAccountLinkEnabled: boolean;
  public accountPortal: boolean;

  constructor(
    private router: Router,
    private readonly store: Store<State>,
    private readonly windowRef: WindowRefService,
    private readonly customerFeatureToggleService: CustomerFeatureToggleService,
    private readonly featureToggleService: FeatureToggleService,
  ) {
    this.accountPortal = this.featureToggleService.getToggles()['VE-5143_2025-12-31_Account-Portal'];
    this.isUserAccountLinkEnabled = this.customerFeatureToggleService.isFeatureAvailable(CustomerFeature.UserPopupAccountLink);
  }

  public signOut() {
    this.onSignOut.emit();
  }

  navigateToPortal() {
    this.router.navigate(['/portal']);
  }

  public close() {
    this.onClose.emit();
  }

  public navigate(user: User, click: PatronAccountClick, customLink?: string): void {
    if (!customLink) {
      switch (click) {
        case PatronAccountClick.PatronName: {
          this.store.dispatch(openBookshelfAccountTab({accountTab: AccountTabs.PROFILE}));
          break;
        }
        case PatronAccountClick.CheckoutsCount: {
          this.store.dispatch(openBookshelfAccountTab({accountTab: AccountTabs.CHECKOUTS}));
          break;
        }
        case PatronAccountClick.HoldsCount: {
          this.store.dispatch(openBookshelfAccountTab({accountTab: AccountTabs.HOLDS}));
          break;
        }
        case PatronAccountClick.FinesFeesAmount: {
          this.store.dispatch(openBookshelfAccountTab({accountTab: AccountTabs.FINES}));
          break;
        }
      }
    } else {
      this.windowRef.nativeWindow().open(customLink, '_blank');
    }

    this.close();
  }
}
