import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DictionariesService } from 'app/services/dictionaries.service';
import { I18NService } from 'app/services/i18-n.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FacetSchema } from 'search/facets/models/facet-schema';
import { makeFacetSchemaWithData } from 'search/facets/models/facet-schema-with-data';
import { AllResponseFacets, FacetResult, ResponseFacet } from 'search/facets/models/resource-facet';
import { FacetsSchemaService } from 'search/facets/services/facets-schema.service';
import { ResourceFacetBlock } from 'search/models/filter-panel';
import { MappedSearchObject, SearchRequestFacets } from 'search/models/search-object';
import { SearchService } from 'search/services/search.service';
import { MetadataItemData } from 'user/models/resource-item-data';

@Injectable()
export class FacetMapperService {

  constructor(
    private readonly dictionariesService: DictionariesService,
    private readonly i18NService: I18NService,
    private readonly facetsSchemaService: FacetsSchemaService,
    private readonly searchService: SearchService,
    private readonly translateService: TranslateService,
  ) { }

  public mapGetAllFacets(data: Observable<AllResponseFacets>, query: MappedSearchObject) {
    return data.pipe(
      switchMap((response: AllResponseFacets) => {
        const facetsQuery = query.filters ? { ...query.filters.facets } : {};
        const untyped = response as any;

        const notFoundFacets = this.collectNotFoundFacets(facetsQuery, response);
        const notFoundAgentsAndConcepts = this.collectNotFoundIdsForAgentAndConcept(notFoundFacets);

        const metadataItems = notFoundAgentsAndConcepts.length > 0 && this.searchService.getMetadataIds(notFoundAgentsAndConcepts)
          || of([] as MetadataItemData[]);
        return metadataItems.pipe(
          map((resources: MetadataItemData[]) => {

            this.enrichResourcesWithLabelsForType(resources, notFoundFacets, 'Agent');
            this.enrichResourcesWithLabelsForType(resources, notFoundFacets, 'Concept');

            Object.keys(notFoundFacets)
              .filter((key) => this.isNotAgentOrConcept(key))
              .forEach((key) => {
                notFoundFacets[key].notFound = notFoundFacets[key].notFound.map((id: string) => {
                  return {
                    id,
                    label: id.replace('_', ' '),
                    count: 0,
                  };
                });
              });

            return notFoundFacets;
          }),
          map((nullApplied: any) => {
            Object.keys(nullApplied).forEach((key) => {
              if (nullApplied[key].notFound.length > 0) {
                const index = untyped[key].data.findIndex((f: ResponseFacet) => !nullApplied[key].appliedIds.some((id: string) => f.id === id));
                untyped[key].data.splice(index, 0, ...nullApplied[key].notFound);
                untyped[key].totalResults += nullApplied[key].notFound.length;
              }
            });
            return response;
          }),
        );
      }),
      map((response: AllResponseFacets) => {
        if (response.materialType && response.materialType.data.length) {
          response.materialType.data = this.enrichFormats(response.materialType.data);
        }
        if (response.language && response.language.data.length) {
          response.language.data = this.enrichLangs(response.language.data);
        }
        if (response.intendedAudience && response.intendedAudience.data.length) {
          response.intendedAudience.data = this.enrichAges(response.intendedAudience.data);
        }
        if (response.literaryForm && response.literaryForm?.data?.length) {
          response.literaryForm.data = this.enrichLabel(response.literaryForm.data, 'literaryForm');
        }
        return this.addNewModel(response);
      }),
    );
  }

  public mapGetSingleFacet(data: Observable<FacetResult<ResponseFacet>>, facetKey: string) {
    return data.pipe(
      map((response: FacetResult<ResponseFacet>) => {
        if (facetKey === ResourceFacetBlock.FORMATS) {
          response.data = this.enrichFormats(response.data);
        }
        if (facetKey === ResourceFacetBlock.LOCATION) {
          response.data = this.enrichLocations(response.data);
        }
        if (facetKey === ResourceFacetBlock.LANGUAGE) {
          response.data = this.enrichLangs(response.data);
        }
        if (facetKey === ResourceFacetBlock.AGES) {
          response.data = this.enrichAges(response.data);
        }
        return response;
      }),
    );
  }

  private isNotAgentOrConcept(key: string) {
    return key !== 'agent' && key !== 'concept';
  }

  private enrichResourcesWithLabelsForType(
    resources: MetadataItemData[],
    notFoundFacets: any,
    type: string,
  ) {
    const notFoundTypedFacet = resources.filter((r) => r.entityType === type)
      .map((r) => {
        return {
          id: r.id,
          label: r.label,
          count: 0,
        };
      });
    const lowerCaseType = type.toLowerCase();
    if (notFoundTypedFacet.length > 0 && notFoundFacets[lowerCaseType]) {
      notFoundFacets[lowerCaseType].notFound = notFoundTypedFacet;
    }
  }

  private collectNotFoundIdsForAgentAndConcept(notFoundFacets: any) {
    return []
      .concat(notFoundFacets.agent && notFoundFacets.agent.notFound || [])
      .concat(notFoundFacets.concept && notFoundFacets.concept.notFound || []) as string[];
  }

  private collectNotFoundFacets(facetsQuery: SearchRequestFacets, response: AllResponseFacets) {
    const untyped = response as any;
    return Object.keys(facetsQuery).reduce((prev: any, current: string) => {
      if (facetsQuery[current] instanceof Array) {
        const appliedIds = facetsQuery[current] as string[];
        const facetKey = current.replace('Ids', '');
        const responseElement = untyped[facetKey] as FacetResult<ResponseFacet>;
        const dataIds = responseElement?.data.map((f: ResponseFacet) => f.id);
        prev[facetKey] = {
          appliedIds,
          notFound: appliedIds.filter((id) => !dataIds.some((f) => f === id)),
        };
      }
      return prev;
    }, {});
  }

  private addNewModel(response: any): AllResponseFacets {
    const facetsSchemas = this.facetsSchemaService.getActualFacetSchemas();
    response.newModel = facetsSchemas.map((schema: FacetSchema) => makeFacetSchemaWithData({
      schema,
      data: response[schema.mapField],
    }));
    return response;
  }

  public getModelForFacet(facetKey: string, response:any) {
    const facetsSchemas = this.facetsSchemaService.getActualFacetSchemas();
    const schema = facetsSchemas.find(schemaRecord => {
      return schemaRecord.mapField == facetKey;
    });
    return makeFacetSchemaWithData({
      schema,
      data: response
    });
  }

  private enrichFormats(data: ResponseFacet[]) {
    return data.reduce((acc, format) => {
      format.label = this.i18NService.getFormatFacetTitle(format.id);
      acc.push(format);
      return acc;
    }, []);
  }

  private enrichLocations(data: ResponseFacet[]) {
    return data.reduce((acc, location) => {
      location.label = this.i18NService.getLocationFacetTitle(location.id);
      acc.push(location);
      return acc;
    }, []);
  }

  private enrichLangs(filters: ResponseFacet[]) {
    filters.forEach((filter) => {
      filter.label = this.i18NService.getLangFacetTitle(filter.id);
    });
    return filters;
  }

  private enrichAges(filters: ResponseFacet[]) {
    return this.enrichLabel(filters, 'audience');
  }

  private enrichLabel(filters: ResponseFacet[], key: string) {
    filters.forEach((filter) => {
      const translation = this.translateService.instant(`${key}|${filter.id}`);
      if (!translation.startsWith(`${key}|`)) {
        filter.label = translation;
      }
    });
    return filters;
  }
}
