import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {GridViewV2Component} from './components/grid-view-v2/grid-view-v2.component';
import {HeaderAnnouncementV2Component} from './components/header-announcement-v2/header-announcement-v2.component';
import {HomePagePreviewV2Component} from './components/home-page-preview-v2/home-page-preview-v2.component';
import {MonsterHelpV2Component} from './components/monster-help-v2/monster-help-v2.component';
import {AccountPortalV2Component} from './components/account-portal-v2/account-portal-v2.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from 'common/shared.module';

@NgModule({
  declarations: [
    HomePagePreviewV2Component,
    GridViewV2Component,
    MonsterHelpV2Component,
    HeaderAnnouncementV2Component,
    AccountPortalV2Component
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule
  ],
  exports: [
    HomePagePreviewV2Component,
    GridViewV2Component,
    MonsterHelpV2Component,
    HeaderAnnouncementV2Component,
    AccountPortalV2Component
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ElementsV2Module {
}
