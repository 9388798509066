<div class="h-100 w-200 position-relative clearfix"
     [@openClose]="listOpenCloseState"
     (@openClose.start)="onOpenCloseAnimation($event)"
     (@openClose.done)="onOpenCloseAnimation($event)">
  <div class="w-50 h-100 float-left">
    <ng-container *ngIf="stepDisplayed == stepDisplayedEnum.lists">
      <app-searchable-bookmarks-lists
        [lists]="lists"
        [authorized]="authorized"
        [isStaffAuthorized]="isStaffAuthorized"
        (openList)="openList($event)"
        (openAvailableList)="openAvailableList()"
      ></app-searchable-bookmarks-lists>
    </ng-container>
  </div>

  <div class="w-50 h-100 float-right">
    <ng-container *ngIf="stepDisplayed == stepDisplayedEnum.singleList">
      <app-searchable-bookmarks-opened-list
      [lists]="emptyLists"
      [authorized]="authorized"
      (closeList)="closeList()"
      ></app-searchable-bookmarks-opened-list>
    </ng-container>

    <ng-container *ngIf="stepDisplayed == stepDisplayedEnum.availableList">
      <app-searchable-bookmarks-available-list
        [lists]="emptyLists"
        [authorized]="authorized"
        (closeList)="closeList()"
      ></app-searchable-bookmarks-available-list>
    </ng-container>
  </div>
</div>
