import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {
  setFavicon(faviconUrl: string) {
    if (!faviconUrl) {
      return;
    }

    // Add a unique query parameter to prevent caching
    const uniqueFaviconUrl = `${faviconUrl}?v=${new Date().getTime()}`;

    const link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
    if (link) {
      link.href = uniqueFaviconUrl;
    } else {
      const newLink: HTMLLinkElement = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = uniqueFaviconUrl;
      document.head.appendChild(newLink);
    }
  }
}