<ng-container [formGroup]="controlContainer.control">
  <select [attr.aria-invalid]="control.invalid && control.touched"
          [class.is-invalid]="control.invalid && control.touched"
          [formControl]="control"
          [id]="inputLabel"
          (change)="onLocationChange($event.target.value)"
          data-automation-id="profile-card-info-value-select"
          class="mb-0 w-100 form-control">
    <option *ngFor="let value of values; trackBy: trackById"
            [value]="value.id"
            [disabled]="value.disabled"
            data-automation-id="profile-card-info-value-select-option">
      {{ value.value }}
    </option>
  </select>
</ng-container>
