import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { isAuthorizedInKeycloak, isStaffAuthorizedInKeycloak } from '../../../keycloak/reducers/keycloak.reducer';
import { listClosed, openList, setSearchFacets } from '../../actions/list.actions';
import {
  ListWithItemsCount,
} from '../../models/list';
import { getLists } from '../../reducers/list.reducer';

enum StepDisplayedEnum {
  lists,
  singleList,
  availableList,
}

@Component({
  selector: 'app-searchable-bookmarks',
  templateUrl: './searchable-bookmarks.component.html',
  styleUrls: ['./searchable-bookmarks.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({left: '-100%'})),
      state('false', style({left: '0%'})),
      transition('true <=> false', animate('0.5s ease-out')),
    ]),
  ],
})
export class SearchableBookmarksComponent implements OnInit, OnDestroy {
  public lists: ListWithItemsCount[] = [];
  public emptyLists: ListWithItemsCount[] = [];
  public stepDisplayed: StepDisplayedEnum = StepDisplayedEnum.lists;
  public listOpenCloseState = false;
  public authorized: boolean;
  public isStaffAuthorized: boolean;

  public readonly stepDisplayedEnum = StepDisplayedEnum;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
  ) {
  }

  ngOnInit() {
    this.store.dispatch(setSearchFacets());
    this.subscriptions.add(this.store.select(getLists).subscribe(lists => {
      this.lists = JSON.parse(JSON.stringify(lists));
      const emptyLists = (JSON.parse(JSON.stringify(lists)) as ListWithItemsCount[]).map(l => ({...l, items: []}));
      emptyLists.sort(this.orderLists);
      this.emptyLists = emptyLists;
    }));

    this.subscriptions.add(combineLatest([
      this.store.select(isAuthorizedInKeycloak),
      this.store.select(isStaffAuthorizedInKeycloak),
    ])
    .subscribe(([authorized, staffAuthorized]) => {
      this.authorized = authorized || staffAuthorized;
      this.isStaffAuthorized = staffAuthorized;
    }));
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onOpenCloseAnimation(event: AnimationEvent): void {}

  public closeList() {
    this.stepDisplayed = this.stepDisplayedEnum.lists;
    this.listOpenCloseState = false;
    this.store.dispatch(listClosed());
  }

  public openList(list: ListWithItemsCount) {
    this.stepDisplayed = this.stepDisplayedEnum.singleList;
    this.listOpenCloseState = true;
    this.store.dispatch(openList({listId: list.id}));
  }

  public openAvailableList() {
    this.stepDisplayed = this.stepDisplayedEnum.availableList;
    this.listOpenCloseState = true;
  }

  private orderLists(a: ListWithItemsCount, b: ListWithItemsCount) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  }
}
