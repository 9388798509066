<div class="bookshelf-item" data-automation-id="bookshelf-item-card">
  <div class="card bookshelf-item-card">
    <div class="card-body bookshelf-item-content"
         [ngClass]="{'bookshelf-item-content-disabled': item.entity.isUnresolved}">
      <div class="card-content">
        <div class="bookshelf-item-cover mr-5" data-automation-id="bookshelf-item-card-cover"
             *ngIf="showCoverImage">
          <app-cover
            [config]="item.entity.coverConfig"
            [title]="item.entity.title"
            [author]="item.entity.primaryAgent?.label"
            [materialType]="item.entity.format"
            size="small">
          </app-cover>
        </div>
        <div class="bookshelf-item-section mr-4">
          <ng-container *ngIf="item.entity.id && !item.entity.isUnresolved; else noLinkOrResourceTitle">
            <a (click)="onNavigation(item.entity.id, item.entity.entityType, $any(item.entity).tabInfo)"
               (keydown.enter)="onNavigation(item.entity.id, item.entity.entityType, $any(item.entity).tabInfo)"
               [queryParams]="{id: item.entity.id, entityType: item.entity.entityType}"
               [routerLink]="['/search', 'card']"
               class="entity-link overflow-hidden"
               data-automation-id="bookshelf-item-card-content">
              <div [id]="ariaTitleId"
                   [tippy]="item.entity.title"
                   class="bookshelf-item-title line-clamp line-clamp--two mb-1"
                   data-automation-id="bookshelf-item-card-title">
                {{ item.entity.title }}
              </div>
            </a>
          </ng-container>
          <div class="bookshelf-item-author line-clamp line-clamp--one mb-1"
               [tippy]="item.entity.primaryAgent.label"
               *ngIf="isExtendedCard && item.entity.primaryAgent?.label"
               data-automation-id="bookshelf-item-card-author">
            <span class="mr-1" translate>bookshelfItemByAuthor</span>
            <ng-container *ngIf="item.entity.primaryAgent.id; else noAuthorLinkLabel">
              <a class="bookshelf-item-author--link"
                 data-automation-id="bookshelf-item-card-author-link"
                 (click)="onNavigation(item.entity.primaryAgent.id, entityTypes.AGENT)"
                 (keydown.enter)="onNavigation(item.entity.primaryAgent.id, entityTypes.AGENT)"
                 [queryParams]="{id: item.entity.primaryAgent.id, entityType: entityTypes.AGENT}"
                 [routerLink]="['/search', 'card']">
                {{ item.entity.primaryAgent.label }}
              </a>
            </ng-container>
            <ng-template #noAuthorLinkLabel>
              <span *ngIf="item.entity.primaryAgent.label"
                    class="bookshelf-item-author--no-link">
                {{ item.entity.primaryAgent.label }}
              </span>
            </ng-template>
          </div>
          <div class="bookshelf-item-format line-clamp line-clamp--one"
               [tippy]="item.entity.format"
               data-automation-id="bookshelf-item-card-format"
               *ngIf="isExtendedCard && item.entity.format">
            <span class="mr-1" translate>bookshelfItemFormat</span>
            {{ item.entity.format }}
          </div>
          <ng-template #noLinkOrResourceTitle>
            <span class="bookshelf-item-title bookshelf-item-title--no-link line-clamp line-clamp--two"
                  [tippy]="item.entity.title || ('resourceIsNotAvailable' | translate)"
                  data-automation-id="bookshelf-item-card-title">
              {{ item.entity.title || ('resourceIsNotAvailable' | translate) }}
            </span>
          </ng-template>
          <div class="availability-container">
            <div class="availability-icon" *ngFor="let icon of materialTabs">
              <button 
              mat-icon-button 
              matTooltip={{icon.formatGroup}}
              class="icon-button"
              >
                <img src="{{ icon.url }}" [style.filter]="icon.filter" alt="{{ icon.label }}"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="actions d-flex" *ngIf="item.entity.id">
        <app-entity-options-button
          [entityType]="item.entity.entityType"
          [disabled]="item.entity.isUnresolved"
          [resourceData]="resourceData"
          [editionsData]="item.entity.editionsData"
          [ariaDescribedBy]="ariaTitleId"
          [smallButton]="true">
        </app-entity-options-button>
      </div>
    </div>
    <span class="pl-5 bookshelf-item-label"
          *ngIf="item.entity.isUnresolved && item.entity?.personalLists?.[0]?.recordId">
      {{'suppresedItemLabel' | translate: {recordId: item.entity.personalLists[0].recordId} }}
    </span>
    <div class="card-footer d-flex align-items-center justify-content-between"
         data-automation-id="bookshelf-item-card-actions">
      <ins-bs-checkbox [class.card-element-hidden]="hideCheckbox"
                       data-automation-id="bookshelf-item-select-card-checkbox"
                       (change)="toggleCardSelection()"
                       [checked]="item.selected"
                       [ariaLabelledby]="ariaTitleId"
                       [containerClass]="['ml-1 mr-3']">
        <ng-container>
          <span>{{ (item.selected ? 'selected' : 'select') | translate }}</span>
        </ng-container>
      </ins-bs-checkbox>
      <ng-container *ngIf="item.entity.id && !item.entity.isUnresolved">
        <app-share-icons-button
          *ngIf="bookshelfShowShareIcon"
          [smallButton]="true"
          [shareData]="shareData"
        ></app-share-icons-button>
      </ng-container>
      <div *ngIf="isExtendedCard && item.entity.checkoutDate"
           class="card-footer--date"
           data-automation-id="bookshelf-item-checkout-date">
        <span class="mr-2" translate>bookshelfItemCardDate</span>
        {{ item.entity.checkoutDate | date : 'longDate' }}
      </div>
    </div>
  </div>
</div>
