<div *ngIf="isDisplayed"
     class="bookshelf d-block"
     role="complementary" aria-labelledby="bookshelf-title"
     [style.height]="expanded ? manualHeight : '56px'"
     [style.max-height]="heightToHeader"
     [class.expanded]="expanded" data-automation-id="bookshelf-container" [cdkTrapFocus]="expanded">
  <div class="top-line" (mousedown)="handleDown($event)" (mouseup)="handleUp()"
       aria-hidden="true"></div>

  <ng-template #bookshelfTitleTemplate>
    <span class="bookshelf-title-name" (click)="handleExpand()"
          data-automation-id="bookshelf-title-name"
          id="bookshelf-title">
      <span *ngIf="isXsWidth;else fullBookshelfTitle" translate>myBookshelf</span>
      <ng-template #fullBookshelfTitle>
        <span>{{bookshelfTitle}}</span>
      </ng-template>
    </span>
  </ng-template>

  <div class="bookshelf-content-wrapper">
    <div class="bookshelf-content" data-automation-id="bookshelf-content">
      <div class="bookshelf-work-area" role="tablist">
        <button *ngIf="!tabsConfig.get(bookshelfTabs.ACCOUNT)" class="bookshelf-title pr-5 pl-4" role="tab" (click)="handleExpand()">
          <ng-container *ngTemplateOutlet="bookshelfTitleTemplate"></ng-container>
        </button>
      </div>

      <div class="d-flex flex-nowrap h-100 pl-1"
           ngbNav
           #nav="ngbNav"
           [activeId]="activeTabId"
           (activeIdChange)="setValueAndExpand($event)">
        <ng-container *ngIf="tabsConfig.get(bookshelfTabs.ACCOUNT)" [ngbNavItem]="bookshelfTabs.ACCOUNT">
          <a ngbNavLink
             class="bookshelf-nav-link bookshelf-nav-link-account-tab"
             data-automation-id="bookshelf-tab-link-account"
             (keydown.enter)="handleExpand()">
            <div class="bookshelf-title">
              <span class="fas fa-user mr-1" aria-hidden="true"></span>
              <ng-container *ngTemplateOutlet="bookshelfTitleTemplate"></ng-container>
            </div>
          </a>

          <ng-template ngbNavContent>
            <app-account></app-account>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="tabsConfig.get(bookshelfTabs.BOOKMARKS) && !bookmarksV2Preview" [ngbNavItem]="bookshelfTabs.BOOKMARKS">
          <a ngbNavLink
             #bookmarksTabLink
             class="bookshelf-nav-link"
             (click)="expand()"
             data-automation-id="bookshelf-tab-link-bookmarks">
          <span class="bookshelf-nav-link-content">
            <fa-icon [icon]="bookmarkIcon"></fa-icon>
            <span class="bookshelf-nav-link-content-label" translate>bookmarks</span>
          </span>
          </a>

          <ng-template ngbNavContent>
            <app-bookmarks [hidden]="formData.showcase?.createdFrom.type === showcaseType.list && !formData.showcase.id"></app-bookmarks>
            <app-custom-showcase-form *ngIf="formData.showcase?.createdFrom.type === showcaseType.list && !formData.showcase.id"></app-custom-showcase-form>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="tabsConfig.get(bookshelfTabs.BOOKMARKS) && bookmarksV2Preview" [ngbNavItem]="bookshelfTabs.BOOKMARKS">
          <a ngbNavLink
             #bookmarksTabLink
             class="bookshelf-nav-link"
             (click)="expand()"
             data-automation-id="bookshelf-tab-link-bookmarks">
          <span class="bookshelf-nav-link-content">
            <fa-icon [icon]="bookmarkIcon"></fa-icon>
            <span class="bookshelf-nav-link-content-label" translate>bookmarks</span>
          </span>
          </a>

          <ng-template ngbNavContent>
            <app-searchable-bookmarks [hidden]="formData.showcase?.createdFrom.type === showcaseType.list && !formData.showcase.id"></app-searchable-bookmarks>
            <app-custom-showcase-form *ngIf="formData.showcase?.createdFrom.type === showcaseType.list && !formData.showcase.id"></app-custom-showcase-form>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="tabsConfig.get(bookshelfTabs.SAVED_SEARCHES)" [ngbNavItem]="bookshelfTabs.SAVED_SEARCHES">
          <a ngbNavLink
             class="bookshelf-nav-link"
             data-automation-id="bookshelf-tab-link-saved-searches"
             (click)="expand()">
            <span class="bookshelf-nav-link-content">
              <span class="fas fa-search-plus icon" aria-hidden="true"></span>
              <span class="bookshelf-nav-link-content-label" translate>savedSearchesBookshelfNavLabel</span>
            </span>
          </a>

          <ng-template ngbNavContent>
            <perfect-scrollbar class="ps-show-always" removeTabindexOnYScroll [ngStyle]="{'max-height': maxHeightPerfectScrollbar}"
                               [hidden]="formData.showcase?.createdFrom.type === showcaseType.savedSearch && !formData.showcase.id"
                               data-automation-id="bookshelf-tab-saved-searches-container">
              <app-saved-searches-list></app-saved-searches-list>
            </perfect-scrollbar>
            <app-custom-showcase-form *ngIf="formData.showcase?.createdFrom.type === showcaseType.savedSearch && !formData.showcase.id"></app-custom-showcase-form>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="isAuthPatronFlagEnabled">
          <ng-container *ngIf="tabsConfig.get(bookshelfTabs.SHOWCASES)">
            <ng-container
              *authorizationDirective="{
                permission: sitePermissions.SHOWCASE_VIEW
              }"
              [ngbNavItem]="bookshelfTabs.SHOWCASES"
            >
              <a ngbNavLink
                 class="bookshelf-nav-link"
                 data-automation-id="bookshelf-tab-link-custom-showcases"
                 (click)="expand()">
              <span class="bookshelf-nav-link-content">
                <fa-icon [icon]="showcaseIcon" [classes]="['icon']"></fa-icon>
                <span class="bookshelf-nav-link-content-label" translate>customShowcasesBookshelfNavLabel</span>
              </span>
              </a>

              <ng-template ngbNavContent>
                <app-custom-showcases [hidden]="formData.showcase?.id"></app-custom-showcases>
                <app-custom-showcase-form *ngIf="formData.showcase?.id"></app-custom-showcase-form>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isAuthPatronFlagEnabled">
          <ng-container *ngIf="tabsConfig.get(bookshelfTabs.SHOWCASES)" [ngbNavItem]="bookshelfTabs.SHOWCASES">
            <a ngbNavLink
              class="bookshelf-nav-link"
              data-automation-id="bookshelf-tab-link-custom-showcases"
              (click)="expand()">
              <span class="bookshelf-nav-link-content">
                <fa-icon [icon]="showcaseIcon" [classes]="['icon']"></fa-icon>
                <span class="bookshelf-nav-link-content-label" translate>customShowcasesBookshelfNavLabel</span>
              </span>
            </a>

            <ng-template ngbNavContent>
              <app-custom-showcases [hidden]="formData.showcase?.id"></app-custom-showcases>
              <app-custom-showcase-form *ngIf="formData.showcase?.id"></app-custom-showcase-form>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="tabsConfig.get(bookshelfTabs.READING_HISTORY)" [ngbNavItem]="bookshelfTabs.READING_HISTORY">
          <a ngbNavLink
             class="bookshelf-nav-link"
             data-automation-id="bookshelf-tab-link-reading-history"
             (click)="expand()">
            <span class="bookshelf-nav-link-content">
              <fa-icon [icon]="readingHistoryIcon" [classes]="['icon']"></fa-icon>
              <span class="bookshelf-nav-link-content-label" translate>ReadingHistoryBookshelfNavLabel</span>
            </span>
          </a>

          <ng-template ngbNavContent>
            <app-reading-history-view></app-reading-history-view>
          </ng-template>
        </ng-container>
      </div>
    </div>

    <button class="bookshelf-expand-button"
      [attr.aria-expanded]="expanded"
      [attr.aria-label]="!expanded ? 'Expand bookshelf' : 'Collapse bookshelf'"
      (click)="handleExpand()"
      [attr.data-automation-id]="!expanded ? 'bookshelf-expand-btn-arrow-up' : 'bookshelf-expand-btn-arrow-down'">
          <span class="icon {{ !expanded ? 'icon-angle-up-thin' : 'icon-angle-down-thin'}}"></span>
    </button>
  </div>

  <div [ngbNavOutlet]="nav" [class.d-none]="!expanded" class="h-100"></div>
</div>
